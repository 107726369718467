import { useEffect } from "react";

const Map = ({ id, mapOptions, setLat, setLon, setCity, setProvince, initLat, initLon, setInitLat, setInitLon }) => {

    let marker;

    function OnMainMapLoad(options, id) {
        const map = new window.google.maps.Map(
            document.getElementById(id),
            options
        );

        //on edit a real estate, first initiate the marker
        if (initLat && initLon) {
            marker = new window.google.maps.Marker({
                position: {lat: initLat, lng: initLon},
                map: map,
            });
            map.panTo({lat: initLat, lng: initLon});
            setLat(initLat);
            setLon(initLon);
            setInitLat(false);
            setInitLon(false);
        }

        //set another location on click
        map.addListener("click", (e) => {
            placeMarker(e.latLng, map);
        });
    }

    function getLocation(latLng) {
        const geocoder = new window.google.maps.Geocoder();

        geocoder.geocode({ 'latLng': latLng }, function (results, status) {
            if (status == window.google.maps.GeocoderStatus.OK) {
                /* console.log(results) */
                if (results[1]) {
                    //formatted address
                    /* console.log(results[0]) */
                    //find country name
                    for (var i = 0; i < results[0].address_components.length; i++) {
                        for (var b = 0; b < results[0].address_components[i].types.length; b++) {

                            //there are different types that might hold a city admin_area_lvl_1 usually does in come cases looking for sublocality type will be more appropriate
                            if (results[0].address_components[i].types[b] == "administrative_area_level_1") {
                                //this is the object you are looking for
                                setProvince(results[0].address_components[i].long_name)
                                break;
                            }
                            if (results[0].address_components[i].types[b] == "administrative_area_level_2") {
                                //this is the object you are looking for
                                setCity(results[0].address_components[i].long_name)
                                break;
                            }
                        }
                    }
                    //city data
                    /* console.log(city.short_name + " " + city.long_name) */
                  /*   console.log("province: " + province.long_name)
                    console.log("city: " + city.long_name) */


                } else {
                    console.log("No results found");
                }
            } else {
                console.log("Geocoder failed due to: " + status);
            }
        });
    }

    function placeMarker(latLng, map) {
        if (marker == null) {
            marker = new window.google.maps.Marker({
                position: latLng,
                map: map,
            });
            getLocation(latLng);
            setLat(latLng.lat);
            setLon(latLng.lng);
        }
        else {
            marker.setPosition(latLng);
            getLocation(latLng);
            setLat(latLng.lat);
            setLon(latLng.lng);
        }
        map.panTo(latLng);
    }

    useEffect(() => {
        if (!window.google) {
            const s = document.createElement('script');
            s.type = 'text/javascript';
            s.src = `https://maps.google.com/maps/api/js?key=AIzaSyDZ9_dN1xY_CfDzldbNR-0a31vMZ1yU0Co`;
            const x = document.getElementsByTagName('script')[0];
            x.parentNode.insertBefore(s, x);
            s.addEventListener('load', () => {
                OnMainMapLoad(mapOptions, id);
            });
        } else {
            OnMainMapLoad(mapOptions, id);
        }
    }, [])

    return (
        <div id={id} />
    )
}

export default Map;