import { useState } from 'react'

export function useForm(initialFValues, validateOnChange = false, validate, setExpanded, resetUppy, setResetUppy, setCity, setProvince, setLon, setLat) {

    const [values, setValues] = useState(initialFValues)
    const [errors, setErrors] = useState({})

    const handleInputChange = e => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
        if (validateOnChange)
            validate({ [name]: value })
    }

    const resetForm = () => {
        setValues(initialFValues)
        setErrors({})
        setExpanded(false)
        setResetUppy(!resetUppy)
        setCity('')
        setProvince('')
        setLon('')
        setLat('')
    }

    return {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm

    }
}

export function Form(props) {
    const { children, ...other } = props
    return (
        <form autoComplete="off" {...other}>
            {props.children}
        </form>
    )
}