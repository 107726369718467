import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Home from './js/Home'
import RealEstateList from './js/RealEstateList'
import RealEstateDetails from './js/RealEstateDetails/RealEstateDetails'
import AddRealEstate from './js/RealEstateDetails/AddRealEstate/AddRealEstate'
import SetCompany from './js/Company/SetCompany'
import UpdateCompany from './js/Company/UpdateCompany'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import UpdateRealEstate from './js/RealEstateDetails/UpdateRealEstate/UpdateRealEstate'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from './js/firebaseAuth'
import LoginWithGoogle from './js/LoginWithGoogle'
import Navbar from './js/Navbar'
import { useMemo } from 'react'
import { grey } from '@mui/material/colors'
import Box from '@mui/material/Box'
import { useGlobalState, setGlobalState } from './js/GlobalState'
import useMediaQuery from '@mui/material/useMediaQuery'
import getCountry from './js/getCountry'

if (getCountry() === 'Germany') {
  setGlobalState('defaultCurrency', 'EUR')
}
else if (getCountry() === 'Turkiye') {
  setGlobalState('defaultCurrency', 'TRY')
} else {
  setGlobalState('defaultCurrency', 'USD')
}

function App() {

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  if (!prefersDarkMode) {
    setGlobalState('darkMode', false)
  }


  const [user, /* userLoading, userError */] = useAuthState(auth)

/*   const [darkMode, setDarkMode] = useState(prefersDarkMode) */
  const [darkMode] = useGlobalState('darkMode')

  const themeOptions = useMemo(
    () =>
      createTheme({

        palette: {
          mode: darkMode ? "dark" : "light",
          primary: {
            main: '#e09c37',
          },
          secondary: {
            main: '#ffffff',
          },
          info: {
            main: '#e09c37',
          },
          ...(darkMode === false
            ? {
              // palette values for light mode

            }
            : {
              // palette values for dark mode
              /*           text: {
                          color: grey[300],
                          secondary: grey[200],
                        }, */
            }),
        },
        components: {
          MuiButton: {
            styleOverrides: {
              root: ({ ownerState }) => ({
                ...(ownerState.variant === 'contained' &&
                  ownerState.color === 'primary' && {
                  backgroundColor: '#fa005e',
                  color: '#fff',
                  width: '100%',
                  '&:hover': {
                    background: "#d8276a",
                  }
                }),
              }),
            },
          },
          MuiLink: {
            ...(darkMode === false
              ? {
                styleOverrides: {
                  root: {
                    textDecoration: 'none',
                    color: grey[800],
                    '&:hover': {
                      textDecoration: 'none'
                    }
                  },
                },
              } : {
                styleOverrides: {
                  root: {
                    textDecoration: 'none',
                    '&:hover': {
                      textDecoration: 'none'
                    }
                  },
                },
              }),
          },
        },
        typography: {
          fontFamily: ['"Turkish IMMO"', '"Montserrat"', 'Arial', 'Open Sans'].join(','),
          h1: {
            fontWeight: 300,
            fontSize: '2rem'
          },
          h2: {
            fontWeight: 300,
            fontSize: '1.75rem'
          },
          h3: {
            fontWeight: 500,
            fontSize: '1.25rem'
          },
          h4: {
            fontWeight: 600,
            fontSize: '1.5rem'
          },
          h5: {
            fontWeight: 600
          },
          h6: {
            fontWeight: 500
          }
        },
      }),
    [darkMode],
  );

/*   const adminUser = ['sebe77u@gmail.com'] */



/*   const [userData] = useGlobalState('userData') */
/* 
  console.log(userData) */


  if (user) {

    user.getIdTokenResult().then(idTokenResult => {
      user.admin = idTokenResult.claims.admin
    })

    if (user.admin) {
      console.log('hello, i am admin')
    } else {
      console.log('no adminz :(')
    }


    
      return (
        <ThemeProvider theme={themeOptions}>
          <Router>
            <Box
              variant="div"
              className={`App ${darkMode ? "darkMode" : "lightMode"}`}
              sx={{
                bgcolor: 'background.default',
                color: 'text.primary',
              }}>
              <main className="content">
                <Navbar user={user} />
                <Routes>
                  <Route exact path="/" element={<Home />} />
                  <Route path="/real-estate" element={<RealEstateList />} />
                  <Route path="/real-estate/:id" element={<RealEstateDetails user={user} />} />
                  <Route path="/add-real-estate/" element={<AddRealEstate user={user} />} />
                  <Route path="/add-real-estate/:id" element={<UpdateRealEstate user={user} />} />
                  <Route path="/set-company/" element={<SetCompany user={user} />} />
                  <Route path="/set-company/:id" element={<UpdateCompany user={user} />} />
                  <Route path="*" element={<Home />} />
                </Routes>
              </main>
            </Box>
          </Router>
        </ThemeProvider >
      )
/*     } else {
      (
        <div>
          Please wait until your mail got confirmed.
        </div>
      )
    } */
  } else {
    return (
      <ThemeProvider theme={themeOptions}>
        <LoginWithGoogle />
      </ThemeProvider>
    )
  }
}

export default App;
