import { Link as RouterLink } from 'react-router-dom'
import MenuItem from '@mui/material/MenuItem'
/* import { doc } from 'firebase/firestore'
import { firestore } from './firebase'
import { useDocumentOnce } from 'react-firebase-hooks/firestore' */
import ListItemIcon from '@mui/material/ListItemIcon'
import StoreMallDirectoryIcon from '@mui/icons-material/StoreMallDirectory'
import { useTranslation } from 'react-i18next'

const NavCompany = ({ handleCloseUserMenu, user }) => {
/*     const refCompany = doc(firestore, `/company/${user.uid}`)
    const [snapshot] = useDocumentOnce(refCompany) */

    const { t } = useTranslation();

    return (
        <MenuItem onClick={() => handleCloseUserMenu()} component={RouterLink} to={`/set-company/`}>
            <ListItemIcon>
                <StoreMallDirectoryIcon fontSize="small" />
            </ListItemIcon>
            {t('edit_company')}
        </MenuItem>
    );
}

export default NavCompany;