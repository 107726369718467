import { firestore } from '../firebase'
import { doc } from 'firebase/firestore'
import { useParams, Navigate } from "react-router-dom"
import { useDocumentData } from 'react-firebase-hooks/firestore'
import SetCompany from './SetCompany'

const UpdateCompany = ({user}) => {
    const { id } = useParams()
    const ref = doc(firestore, `/company/${id}`)
    const [data/* , loading, error, snapshot */] = useDocumentData(ref)

    return (
        <>
            {data &&
                <>
                    {user &&
                        <>
                            {user.uid === data.user ?
                                <SetCompany user={user} data={data} id={id} />
                                :
                                <Navigate replace to="/" />
                            }
                        </>
                    }
                </>
            }
        </>
    );
}
 
export default UpdateCompany;