import { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import Link from '@mui/material/Link'
import { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import TurkishMap from './TurkishMap'
import { firestore } from './firebase'
import { query, where, collection } from "firebase/firestore"
import Box from '@mui/material/Box'
import { grey } from '@mui/material/colors'
import CardBox from './CardBox'
import Footer from './Footer'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import turkishCities from './turkishCities.json'
import turkishProvinces from './turkishProvinces.json'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { setGlobalState, useGlobalState } from './GlobalState'
import Grid from '@mui/material/Grid'
import Skeleton from '@mui/material/Skeleton'
import { useTranslation } from 'react-i18next'
import { motion } from 'framer-motion'

const postConverter = {
    fromFirestore(
        snapshot,
        options
    ) {
        const data = snapshot.data(options);
        return {
            ...data,
            id: snapshot.id,
            /*       ref: snapshot.ref */
        };
    },
};

const Home = () => {

    const { t } = useTranslation();

    const theme = useTheme();
    const matchesMobileUp = useMediaQuery(theme.breakpoints.up('md'));

    const [city, setCity] = useState('');
    const [province, setProvince] = useState('');

    const handleChangeCity = (event) => {
        setCity(event.target.value)
    };

    const handleChangeProvince = (event) => {
        setProvince(event.target.value)
    };

    const q = query(
        collection(firestore, "realEstates"),
        /*         limit(3), */
        where("active", "==", true),
    ).withConverter(postConverter)

    const qApartments = query(
        collection(firestore, "realEstates"),
        /*         limit(3), */
        where("active", "==", true),
        where("realEstateType", "==", 'apartment'),
    ).withConverter(postConverter)

    const qHouses = query(
        collection(firestore, "realEstates"),
        /*         limit(3), */
        where("active", "==", true),
        where("realEstateType", "==", 'house'),
    ).withConverter(postConverter)

    const qOffices = query(
        collection(firestore, "realEstates"),
        /*         limit(3), */
        where("active", "==", true),
        where("realEstateType", "==", 'office'),
    ).withConverter(postConverter)

    const qBuildingSites = query(
        collection(firestore, "realEstates"),
        /*         limit(3), */
        where("active", "==", true),
        where("realEstateType", "==", 'buildingSite'),
    ).withConverter(postConverter)

    const qVillas = query(
        collection(firestore, "realEstates"),
        /*         limit(3), */
        where("active", "==", true),
        where("realEstateType", "==", 'villa'),
    ).withConverter(postConverter)

    const qRent = query(
        collection(firestore, "realEstates"),
        /*         limit(3), */
        where("active", "==", true),
        where("marketingTypeGroup", "==", 'rent'),
    ).withConverter(postConverter)

    const qBuy = query(
        collection(firestore, "realEstates"),
        /*         limit(3), */
        where("active", "==", true),
        where("marketingTypeGroup", "==", 'buy'),
    ).withConverter(postConverter)

    const [data, loading/* , error */] = useCollectionData(q)
    const [dataApartments, loadingApartments/* , error */] = useCollectionData(qApartments)
    const [dataHouses, loadingHouses/* , error */] = useCollectionData(qHouses)
    const [dataOffices, loadingOffices/* , error */] = useCollectionData(qOffices)
    const [dataBuildingSites, loadingBuildingSites/* , error */] = useCollectionData(qBuildingSites)
    const [dataVilla, loadingVilla/* , error */] = useCollectionData(qVillas)
    const [dataBuy, loadingBuy/* , error */] = useCollectionData(qBuy)
    const [dataRent, loadingRent/* , error */] = useCollectionData(qRent)

    const allCollections =
        [
            {
                data: data,
                translation: t('all_real_estate_listings'),
                url: '/real-estate?Ra_hi=47.07777787499999&Ra_lo=21.150043499999992&wb_hi=46.68742403674443&wb_lo=31.175574803198373&center_lat=39.36011508941121&center_lng=34.11391068749999&zoom_level=6',
                isLoading: loading,
                filterRealEstateType: [],
                filterMarketingGroup: []
            },
            {
                data: dataApartments,
                translation: t('all_apartment_listings'),
                url: '/real-estate?Ra_hi=47.07777787499999&Ra_lo=21.150043499999992&wb_hi=46.68742403674443&wb_lo=31.175574803198373&center_lat=39.36011508941121&center_lng=34.11391068749999&zoom_level=6',
                isLoading: loadingApartments,
                filterRealEstateType: ['apartment'],
                filterMarketingGroup: []
            },
            {
                data: dataHouses,
                translation: t('all_houses_listings'),
                url: '/real-estate?Ra_hi=47.07777787499999&Ra_lo=21.150043499999992&wb_hi=46.68742403674443&wb_lo=31.175574803198373&center_lat=39.36011508941121&center_lng=34.11391068749999&zoom_level=6',
                isLoading: loadingHouses,
                filterRealEstateType: ['house'],
                filterMarketingGroup: []
            },
            {
                data: dataOffices,
                translation: t('all_offices_listings'),
                url: '/real-estate?Ra_hi=47.07777787499999&Ra_lo=21.150043499999992&wb_hi=46.68742403674443&wb_lo=31.175574803198373&center_lat=39.36011508941121&center_lng=34.11391068749999&zoom_level=6',
                isLoading: loadingOffices,
                filterRealEstateType: ['office'],
                filterMarketingGroup: []
            },
            {
                data: dataBuildingSites,
                translation: t('all_building_sites_listings'),
                url: '/real-estate?Ra_hi=47.07777787499999&Ra_lo=21.150043499999992&wb_hi=46.68742403674443&wb_lo=31.175574803198373&center_lat=39.36011508941121&center_lng=34.11391068749999&zoom_level=6',
                isLoading: loadingBuildingSites,
                filterRealEstateType: ['buildingSite'],
                filterMarketingGroup: []
            },
            {
                data: dataVilla,
                translation: t('all_villa_listings'),
                url: '/real-estate?Ra_hi=47.07777787499999&Ra_lo=21.150043499999992&wb_hi=46.68742403674443&wb_lo=31.175574803198373&center_lat=39.36011508941121&center_lng=34.11391068749999&zoom_level=6',
                isLoading: loadingVilla,
                filterRealEstateType: ['villa'],
                filterMarketingGroup: []
            },
            {
                data: dataBuy,
                translation: t('all_for_sale_listings'),
                url: '/real-estate?Ra_hi=47.07777787499999&Ra_lo=21.150043499999992&wb_hi=46.68742403674443&wb_lo=31.175574803198373&center_lat=39.36011508941121&center_lng=34.11391068749999&zoom_level=6',
                isLoading: loadingBuy,
                filterRealEstateType: [],
                filterMarketingGroup: ['buy']
            },
            {
                data: dataRent,
                translation: t('all_for_rent_listings'),
                url: '/real-estate?Ra_hi=47.07777787499999&Ra_lo=21.150043499999992&wb_hi=46.68742403674443&wb_lo=31.175574803198373&center_lat=39.36011508941121&center_lng=34.11391068749999&zoom_level=6',
                isLoading: loadingRent,
                filterRealEstateType: [],
                filterMarketingGroup: ['rent']
            }
        ]

    const [darkMode] = useGlobalState('darkMode')

    const shuffle = (a) => {
        for (let i = a.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [a[i], a[j]] = [a[j], a[i]];
        }
        return a;
    }

    if (data) {
        shuffle(data)
    }

    return (
        <Box className="home"
            sx={{
                bgcolor: 'background.default',
                color: 'text.primary',
                display: 'flex'
            }}>
            <Box sx={{ position: 'relative' }}>
                <Grid container spacing={{ xs: 1 }}>
                    <Grid item xs={12} md={8}>

                        <Box sx={{ pointerEvents: matchesMobileUp ? 'inherit' : 'none' }}>
                            <TurkishMap />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box sx={{
                            position: matchesMobileUp ? 'inherit' : 'absolute',
                            backgroundColor: matchesMobileUp ? 'transparent' : darkMode ? 'rgb(0 0 0 / 77%)' : 'rgb(255 255 255 / 77%)',
                            maxWidth: matchesMobileUp ? '295px' : '90%',
                            width: '100%',
                            textAlign: matchesMobileUp ? 'left' : 'center',
                            marginTop: matchesMobileUp ? '32px' : '0',
                            top: matchesMobileUp && '-60px',
                            paddingBottom: '20px',
                            borderRadius: '12px',
                            top: matchesMobileUp ? '0' : '50%',
                            left: matchesMobileUp ? '0' : '50%',
                            marginRight: matchesMobileUp ? '0' : '-50%',
                            transform: matchesMobileUp ? '0' : 'translate(-50%, -50%)'
                        }}>
                            <Box sx={{ display: matchesMobileUp ? 'none' : 'block' }}>
                                <FormControl variant="standard" sx={{ m: 1, minWidth: 240 }}>
                                    <InputLabel sx={{ fontSize: '24px', top: -8 }} id="province-select">{t('province')}</InputLabel>
                                    <Select
                                        labelId="province-select"
                                        id="province-select-field"
                                        value={province}
                                        onChange={handleChangeProvince}
                                        label="Provinces"
                                        sx={{ display: 'flex' }}
                                        MenuProps={{
                                            PaperProps: {
                                                className: "province-select-options",
                                                sx: {
                                                    width: '100%',
                                                    maxHeight: '50%',
                                                    display: 'flex'
                                                },
                                            },
                                        }}
                                    >
                                        {turkishProvinces.map((location) => (
                                            <MenuItem component={RouterLink} to={location.to} key={location.value} value={location.label}>
                                                {location.label}
                                            </MenuItem>
                                        ))}

                                    </Select>
                                </FormControl>
                            </Box>
                            <Box>
                                <FormControl variant="standard" sx={{ m: 1, minWidth: 240 }}>
                                    <InputLabel sx={{ fontSize: '24px', top: -8 }} id="city-select">{t('city')}</InputLabel>
                                    <Select
                                        labelId="city-select"
                                        id="city-select-field"
                                        value={city}
                                        onChange={handleChangeCity}
                                        label="Cities"
                                        sx={{ maxWidth: '250px', display: 'flex' }}
                                        MenuProps={{
                                            PaperProps: {
                                                className: "city-select-options",
                                                sx: {
                                                    width: '100%',
                                                    maxHeight: '50%',
                                                    display: 'flex'
                                                    /*                 '& .MuiMenuItem-root': {
                                                                      padding: 2,
                                                                    }, */
                                                },
                                            },
                                        }}
                                    >
                                        {turkishCities.map((location) => (
                                            <MenuItem component={RouterLink} to={location.to} key={location.value} value={location.label}>
                                                {location.label}
                                            </MenuItem>
                                        ))}

                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            {allCollections.map((collection, i) => (
                <Box
                    key={i}
                    className="homeslider"
                    variant="div"
                    sx={{ mb: 6 }}
                >
                    <Link color={grey[500]} onClick={() => { setGlobalState('filterRealEstateType', collection.filterRealEstateType); setGlobalState('filterMarketingGroup', collection.filterMarketingGroup) }} component={RouterLink} className="overview-title" to={collection.url}>
                        {collection.translation}
                    </Link>
                    <div className="overview-vertical-divider"></div>
                    <Box sx={{ borderRadius: '12px', overflow: 'hidden' }}>
                        <Swiper
                            modules={[Navigation]}
                            slidesPerView={1}
                            spaceBetween={16}
                            navigation

                            breakpoints={
                                {
                                    363: {
                                        slidesPerView: 2
                                    },
                                    667: {
                                        slidesPerView: 4
                                    },
                                    1024: {
                                        slidesPerView: 6
                                    },

                                }
                            }
                        >
                            {(collection.isLoading ? Array.from(new Array(6)) : collection.data).map((realEstate, i) => (
                                realEstate ?
                                    <Box
                                        className="real-estate-preview"
                                        key={realEstate.id}
                                        sx={{
                                            bgcolor: 'background.default',
                                            color: 'text.primary',
                                        }}
                                    >
                                        <SwiperSlide key={i}>
                                            <Link component={RouterLink} to={`/real-estate/${realEstate.id}-${realEstate.humanReadableUrl}`}>
                                                <div className="list-card">
                                                    <div className="image-container">
                                                        <div className="image-subcontainer">
                                                            <picture className="img">
                                                                {realEstate.images.length > 0 &&
                                                                    <source srcSet={
                                                                        realEstate.images[0].url + " 500w," +
                                                                        realEstate.images[0].url + " 700w," +
                                                                        realEstate.images[0].url + " 1200w,"
                                                                    }></source>
                                                                }
                                                                <img src="https://firebasestorage.googleapis.com/v0/b/turkishimmo-fd59c.appspot.com/o/template.jpg?alt=media&token=ba7c0355-0d27-4f8a-934e-92d87102343d" />

                                                            </picture>
                                                        </div>
                                                    </div>
                                                    <CardBox realEstate={realEstate} />
                                                </div>
                                            </Link>
                                        </SwiperSlide>

                                    </Box>
                                    :
                                    <Box
                                        className="real-estate-preview"
                                        key={i}
                                        sx={{
                                            bgcolor: 'background.default',
                                            color: 'text.primary',
                                        }}
                                    >
                                        <SwiperSlide key={i}>
                                            <motion.div
                                                initial={{ scale: 0 }}
                                                animate={{ scale: 1 }}
                                                transition={{
                                                    type: "spring",
                                                    stiffness: 260,
                                                    damping: 23
                                                }}
                                            >
                                                <div className="">
                                                    <div className="image-container">
                                                        <div className="image-subcontainer">
                                                            <Skeleton sx={{ borderRadius: '20px' }} variant="rectangular" height={200} />
                                                            <Box sx={{ pt: 0.5 }}>
                                                                <Skeleton />
                                                                <Skeleton width="60%" />
                                                            </Box>
                                                        </div>
                                                    </div>

                                                </div>
                                            </motion.div>
                                        </SwiperSlide>
                                    </Box>
                            ))}

                        </Swiper>
                    </Box>
                </Box>
            ))}
            <Footer />
        </Box>
    );
}

export default Home;