import { useState } from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Checkbox from '@mui/material/Checkbox'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'
import Tooltip from '@mui/material/Tooltip'

const Contact = ({ checkedContacts, setCheckedContacts, allContacts, setShowUpdateContacts, setContactData }) => {

  const [checked, setChecked] = useState(checkedContacts)

  const handleEditContact = (value) => {
    setShowUpdateContacts(true)
    setContactData(value)
  }

  const handleToggle = (value) => () => {
    const currentIndex = checked.findIndex(object => {
      return object.id === value.id
    })
    const newChecked = [...checked]

/*     console.log(value.id) */

    if (checked.some(e => e.id === value.id)) {
      newChecked.splice(currentIndex, 1)
    } else {
      newChecked.push({id: value.id})
    }

    setChecked(newChecked)
    setCheckedContacts(newChecked)
  }
  return (
    <>
      <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', mb: 3 }}>
        {allContacts.map((value, index) => {
          const labelId = `checkbox-list-label-${index}`

          return (
            <ListItem
              key={index}
              secondaryAction={
                <Tooltip title="Edit contact">
                  <IconButton onClick={() => handleEditContact(value)} edge="end" aria-label="comments">
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              }
              disablePadding
            >
              <ListItemButton role={undefined} onClick={handleToggle(value)} dense>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={checked.some(e => e.id === value.id)}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={value.name} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </>
  );
}

export default Contact;