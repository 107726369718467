import Box from '@mui/material/Box'
import { Typography } from '@mui/material'
import estateTypeName from './RealEstateDetails/estateTypeName'
import { grey } from '@mui/material/colors'
import { doc } from 'firebase/firestore'
import { firestore } from './firebase'
import { useDocument } from 'react-firebase-hooks/firestore'
import Avatar from '@mui/material/Avatar'
import Tooltip from '@mui/material/Tooltip'
import CalculatePrice from './Currency/CalculatePrice'
import CurrencyIcon from './Currency/CurrencyIcon'
import { useTranslation } from 'react-i18next'

const CardBox = ({ realEstate }) => {

    const { t } = useTranslation();

    const ref = doc(firestore, 'company', realEstate.user)
    const [thisCompany] = useDocument(ref)

    return (
        <Box sx={{ mb: 1, display: 'flex', flexDirection: 'column', alignContent: 'flex-end' }} className="title-container">
            <Typography sx={{lineHeight: '1'}} color={grey[500]} className="preheader">
                {realEstate.marketingTypeGroup.charAt(0).toUpperCase() + realEstate.marketingTypeGroup.substring(1)} {realEstate.realEstateType === "apartment" || realEstate.realEstateType === "office" ? "an" : "a"} {estateTypeName(realEstate.realEstateType)} in {realEstate.city} ({realEstate.province})
            </Typography>
            <Typography sx={{minHeight: '46px', fontWeight: '600'}} variant="h3" className="h3">
            {realEstate.title}
            </Typography>
            <Box sx={{ alignItems: 'center', display: 'flex', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                {thisCompany &&
                    <Tooltip title={thisCompany.data().companyName}>
                        <Avatar
                            alt={thisCompany.data().companyName}
                            src={thisCompany.data().image.length > 0 && thisCompany.data().image[0].url}
                            sx={{ width: 25, height: 25 }}
                        />
                    </Tooltip>
                }
                <Box sx={{ pl: 1, fontWeight: 700}} className="pricebox" color={grey[500]} >
                    {t('price')}: <CalculatePrice price={realEstate.price} /><CurrencyIcon iconStyle={{ fontSize: '18px', mr: 1, position: 'relative', top: '3px' }} />
                </Box>
            </Box>
        </Box>
    );
}

export default CardBox;