import { useDocument } from 'react-firebase-hooks/firestore'
import Avatar from '@mui/material/Avatar'
import { doc } from 'firebase/firestore'
import { firestore } from './../firebase'
import Box from '@mui/material/Box'
import useMediaQuery from '@mui/material/useMediaQuery'

const RealEstateCompany = ({ createdBy }) => {

    const refCompany = doc(firestore, 'company', createdBy)
    const [thisCompany] = useDocument(refCompany)
    const matchesMq = useMediaQuery('(min-width:900px)')

    let showCardOnSide = matchesMq ? 'flex' : 'inherit'

    return (
        <>
            {thisCompany &&
                <Box sx={{ display: showCardOnSide, alignItems: 'center' }}>
                    <Avatar
                        alt={thisCompany.data().companyName}
                        src={thisCompany.data().image.length > 0 && thisCompany.data().image[0].url}
                        sx={{ width: 80, height: 80, mb: 1 }}
                    />
                    <Box sx={{ pl: 1 }}>
                        {thisCompany.data().companyName}
                    </Box>
                </Box>
            }
        </>
    );
}

export default RealEstateCompany;