import { useMemo, useEffect } from "react";
import Uppy from '@uppy/core'
import Transloadit from '@uppy/transloadit'
import { Dashboard } from '@uppy/react'
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import { useGlobalState } from '../../GlobalState'

const UppyField = ({ images, setImages, resetUppy }) => {

  const [darkMode] = useGlobalState('darkMode')
  let resizeId

  const uppy = useMemo(() => {
    return new Uppy({
      restrictions: {
        maxFileSize: 10000000,
        allowedFileTypes: [
          ".jpg",
          ".jpeg",
          ".png",
          ".JPG",
          ".JPEG",
          ".PNG"
        ]
      }
    })
      .use(Transloadit, {
        id: 'transloaditImmo',
        params: {
          auth: {
            key: 'e2321a869fbf409e89e20f15417c9bb3'
          },
          steps: {
            resize: {
              robot: '/image/resize',
              width: 1280,
              height: 720,
              resize_strategy: 'fit',
              text: [
                {
                  text: '© TürkiyeReal.Estate',
                  size: 12,
                  font: 'Ubuntu',
                  color: '#eeeeee',
                  valign: 'bottom',
                  align: 'right',
                  x_offset: 16,
                  y_offset: -10
                }
              ]
            },
            exported: {
              use: [
                "resize"
              ],
              robot: "/google/store",
              credentials: "Google Storage Credentials"
            }
          }
        },
        waitForEncoding: true
      })
      .on('transloadit:result', (stepName, result) => {
        { console.log(stepName) }
        { console.log(result) }
        setImages(images => [...images, { url: result.ssl_url, id: result.id }]);

/*         if (stepName === 'resize') {
          setImages(images => [...images, { url: result.ssl_url, id: result.id }]);
        }
        if (stepName === 'resizeCard') {
          images.map((image) => {
            console.log(image.id)
            console.log(resizeId)
            if (image.id === resizeId) {
              image.url300 = result.ssl_url
            }
          })
        } */
        /* images.indexOf(result.ssl_url) === -1 ? images.push(result.ssl_url) : console.log("This item already exists"); */
        /*     console.log(stepName) */
        /*     const file = uppy.getFile(result.localId)
            var resultContainer = document.createElement('div')
            resultContainer.innerHTML = `
              <div>
                <h3>Name: ${file.name}</h3>
                <img src="${result.ssl_url}" /> <br />
                <a href="${result.ssl_url}">View</a>
              </div>
            `
            console.log(resultContainer) */
        /*     document
              .getElementById('uppy-transloadit-result'); */
      })
    // or
  }, [setImages])

  useEffect(() => {
    return () => uppy.reset(true)
  }, [resetUppy])

  useEffect(() => {
    return () => uppy.close({ reason: 'unmount' })
  }, [uppy])

  /*   const uppy = useUppy(() => {
      return new Uppy({
        restrictions: {
          maxFileSize: 10000000,
          allowedFileTypes: [
            ".jpg",
            ".jpeg",
            ".png",
            ".JPG",
            ".JPEG",
            ".PNG"
          ]
        }
      })
    }) */




  return (
    <Dashboard
      note="Images only (jpg or png), up to 10 MB per file. Best results in 16:9 format."
      uppy={uppy}
      theme={darkMode ? 'dark' : 'light'}
      proudlyDisplayPoweredByUppy={false}
      locale={{
        strings: {
          dropHereOr: 'Drop here or %{browse}',
          browse: 'browse',
        },
      }}
    />
  )
}

export default UppyField;