/* import NavAndFilter from './NavAndFilter' */
import { useState, useEffect } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { createSearchParams, useNavigate } from "react-router-dom"
import Link from '@mui/material/Link'
import { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'
import { Typography } from '@mui/material'
import Divider from '@mui/material/Divider'
import { grey } from '@mui/material/colors'
import CardBox from './CardBox'
import { setGlobalState, useGlobalState } from './GlobalState'
import { Wrapper } from '@googlemaps/react-wrapper'
import Map from './Map'
import * as geofirestore from 'geofirestore'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import Popover from '@mui/material/Popover'
import FilterListIcon from '@mui/icons-material/FilterList'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import Badge from '@mui/material/Badge'
import { motion } from 'framer-motion'

const variants = {
    open: { opacity: 1, x: 0, height: '100%' },
    closed: { opacity: 0, x: "100%", height: '0', display: 'none' },
}

const RealEstateList = () => {

    const [darkMode] = useGlobalState('darkMode')
    const [apiKey] = useGlobalState('mapsAPIkey')
    const [posts, setPosts] = useState([])
    const [markersArray, setMarkersArray] = useState([])
    const navigate = useNavigate()
    const urlParams = new URLSearchParams(window.location.search)
    /*     const bounds = urlParams.get('bounds') */
    const centerLat = urlParams.get('center_lat')
    const centerLng = urlParams.get('center_lng')
    const zoomLvl = urlParams.get('zoom_level')
    const lat1 = urlParams.get('Ra_hi')
    const lon1 = urlParams.get('Ra_lo')
    const lat2 = urlParams.get('wb_hi')
    const lon2 = urlParams.get('wb_lo')

    const [filterRealEstateType] = useGlobalState('filterRealEstateType')
    const [filterMarketingGroup] = useGlobalState('filterMarketingGroup')

    const [showFilters, setShowFilters] = useState(false)

    const [anchorEl, setAnchorEl] = useState(null)
    const [popoverContent, setPopoverContent] = useState()

    const open = Boolean(true);
    const id = open ? 'simple-popover' : undefined;


    const handleClose = () => {
        setAnchorEl(null)
    }

    function distance(lat1, lat2, lon1, lon2) {

        lon1 = lon1 * Math.PI / 180;
        lon2 = lon2 * Math.PI / 180;
        lat1 = lat1 * Math.PI / 180;
        lat2 = lat2 * Math.PI / 180;

        // Haversine formula
        let dlon = lon2 - lon1;
        let dlat = lat2 - lat1;
        let a = Math.pow(Math.sin(dlat / 2), 2)
            + Math.cos(lat1) * Math.cos(lat2)
            * Math.pow(Math.sin(dlon / 2), 2);

        let c = 2 * Math.asin(Math.sqrt(a));

        // Radius of earth in kilometers. Use 3956
        // for miles
        let r = 6371;

        // calculate the result
        return ((c * r).toFixed(2));
    }

    const filterQuery = (queryCollect) => {
        filterRealEstateType.forEach((key) => {
            queryCollect = queryCollect.where('realEstateType', '==', key)
        })
        filterMarketingGroup.forEach((key) => {
            queryCollect = queryCollect.where('marketingTypeGroup', '==', key)
        })
        return queryCollect
    }

    firebase.initializeApp({
        apiKey: "AIzaSyAlffJjCpAyHGR8LM737t9Ht07ZcsFWOcY",
        authDomain: "turkishimmo-fd59c.firebaseapp.com",
        projectId: "turkishimmo-fd59c",
        storageBucket: "turkishimmo-fd59c.appspot.com",
        messagingSenderId: "135000526831",
        appId: "1:135000526831:web:59a329982cf92bf7b3701a",
        measurementId: "G-BMNZKQ5W7E"
    })
    const firestore = firebase.firestore()

    const GeoFirestore = geofirestore.initializeApp(firestore)
    const geocollection = GeoFirestore.collection('realEstates')
    const queryCollect = geocollection.near({
        center: new firebase.firestore.GeoPoint(Number(centerLat), Number(centerLng)),
        radius: Number(distance(lat1, lon1, lat2, lon2) / 2.9),
    })
    const queryCollectFiltered = filterQuery(queryCollect)
    const geoQuery = queryCollectFiltered.limit(10)

    const [zoom, setZoom] = useState(zoomLvl ? Number(zoomLvl) : 6) // initial zoom
    const [center, setCenter] = useState({
        lat: centerLat ? Number(centerLat) : 39.445007,
        lng: centerLng ? Number(centerLng) : 34.157856,
    })

    const onIdle = (m) => {
        /*                  console.log(m.getBounds().getSouthWest().lat()); */
        geoQuery.get().then((value) => {
            setPosts(value.docs)
        })

        navigate({
            search: createSearchParams({
                'Ra_hi': m.getBounds().getNorthEast().lng(),
                'Ra_lo': m.getBounds().getSouthWest().lng(),
                'wb_hi': m.getBounds().getNorthEast().lat(),
                'wb_lo': m.getBounds().getSouthWest().lat(),
                'center_lat': m.getCenter().lat(),
                'center_lng': m.getCenter().lng(),
                'zoom_level': m.getZoom(),
            }).toString()
        })

        setZoom(m.getZoom())
        setCenter(m.getCenter().toJSON())
    }

    useEffect(() => {
        geoQuery.get().then((value) => {
            setPosts(value.docs)
        })
    }, [filterRealEstateType, filterMarketingGroup])

    // Gesture handling for map
    const matches = useMediaQuery('(min-width:600px)')
    const checkGestureHandling = () => {
        if (matches) {
            return 'greedy'
        } else {
            return 'cooperative'
        }
    }

    const onMouseEnter = (e) => {
        let elementContainerToHover = document.getElementById(`bubble-${e}`)

        if (elementContainerToHover) {
            elementContainerToHover.style.fontSize = '18px'
            elementContainerToHover.style.zIndex = '2'
        }
    }
    const onMouseLeave = (e) => {
        let elementContainerToHover = document.getElementById(`bubble-${e}`)

        if (elementContainerToHover) {
            elementContainerToHover.style.fontSize = '14px'
            elementContainerToHover.style.zIndex = '1'
        }
    }

    return (
        <Box
            variant="div"
            sx={{
                bgcolor: 'background.default',
                color: 'text.primary',
            }}
        >
            <div className="overview-list real-estate-list">
                <div className="content-list">
                    <Box variant="div" sx={{ mb: 3 }} className="list-header">
                        <Box onClick={() => setShowFilters(!showFilters)} sx={{ mb: 2, justifyContent: 'flex-end', userSelect: 'none', cursor: 'pointer', color: grey[500], display: 'flex', alignItems: 'center' }}>
                            <Badge overlap="circular" color="primary" variant="dot" invisible={filterMarketingGroup.length > 0 || filterRealEstateType.length > 0 ? false : true}>
                                <FilterListIcon sx={{
                                    fontSize: '30px',
                                    color: '#fa005e',

                                    '&:hover': {
                                        color: '#ff3480',
                                    }
                                }} />
                            </Badge>
                            <Typography variant="h4">
                                Filter
                            </Typography>
                        </Box>
                        <Box sx={{ mb: 2 }}>
                            <motion.div
                                animate={showFilters ? "open" : "closed"}
                                variants={variants}
                            >

                                <FormControl>
                                    <FormLabel id="row-radio-marketing-type">Marketing types</FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="row-radio-marketing-type"
                                        name="row-radio-buttons-group-marketing-type"
                                        value={filterMarketingGroup}
                                    >
                                        <FormControlLabel onClick={() => setGlobalState('filterMarketingGroup', [])} value="" control={<Radio />} label="All" />
                                        <FormControlLabel onClick={() => setGlobalState('filterMarketingGroup', ['buy'])} value="buy" control={<Radio />} label="To buy" />
                                        <FormControlLabel onClick={() => setGlobalState('filterMarketingGroup', ['rent'])} value="rent" control={<Radio />} label="To rent" />
                                    </RadioGroup>
                                </FormControl>

                                <FormControl>
                                    <FormLabel id="row-radio-real-estate-type">Real estate type</FormLabel>
                                    <RadioGroup
                                        row
                                        value={filterRealEstateType}
                                        aria-labelledby="row-radio-real-estate-type"
                                        name="row-radio-buttons-group-real-estate"
                                    >
                                        <FormControlLabel onClick={() => setGlobalState('filterRealEstateType', [])} value="" control={<Radio />} label="All" />
                                        <FormControlLabel onClick={() => setGlobalState('filterRealEstateType', ['house'])} value="house" control={<Radio />} label="Houses" />
                                        <FormControlLabel onClick={() => setGlobalState('filterRealEstateType', ['apartment'])} value="apartment" control={<Radio />} label="Apartments" />
                                        <FormControlLabel onClick={() => setGlobalState('filterRealEstateType', ['office'])} value="office" control={<Radio />} label="Offices" />
                                        <FormControlLabel onClick={() => setGlobalState('filterRealEstateType', ['buildingSite'])} value="buildingSite" control={<Radio />} label="Building sites" />
                                        <FormControlLabel onClick={() => setGlobalState('filterRealEstateType', ['villa'])} value="villa" control={<Radio />} label="Villas" />
                                    </RadioGroup>
                                </FormControl>



                            </motion.div>
                        </Box>
                        <Divider light />
                    </Box>
                    <div className="real-estate-preview-list">
                        {posts &&
                            posts.map((realEstate) => (
                                <motion.div
                                    key={realEstate.data().id}
                                    initial={{ scale: 0 }}
                                    animate={{ scale: 1 }}
                                    transition={{
                                        type: "spring",
                                        stiffness: 260,
                                        damping: 23
                                    }}
                                >
                                    <div className="real-estate-preview">
                                        <Link component={RouterLink} to={`/real-estate/${realEstate.data().id}-${realEstate.data().humanReadableUrl}`}>
                                            <div onMouseEnter={(e) => onMouseEnter(realEstate.data().id)} onMouseLeave={(e) => onMouseLeave(realEstate.data().id)} className={`list-card`}>
                                                <div className="image-container">
                                                    <Swiper
                                                        modules={[Navigation]}
                                                        slidesPerView={1}
                                                        navigation={realEstate.data().images.length > 1 ? true : false}
                                                    >
                                                        <div className="image-subcontainer">

                                                            {realEstate.data().images.length < 1 ?
                                                                <SwiperSlide>
                                                                    <picture className="img">
                                                                        <img src="https://firebasestorage.googleapis.com/v0/b/turkishimmo-fd59c.appspot.com/o/template.jpg?alt=media&token=ba7c0355-0d27-4f8a-934e-92d87102343d" />
                                                                    </picture>
                                                                </SwiperSlide>
                                                                :
                                                                realEstate.data().images.map((image, i) => (
                                                                    <SwiperSlide obj={realEstate.data().images} key={i}>
                                                                        <picture className="img">
                                                                            <source srcSet={
                                                                                realEstate.data().images[i].url + " 500w," +
                                                                                realEstate.data().images[i].url + " 700w," +
                                                                                realEstate.data().images[i].url + " 1200w,"
                                                                            }></source>
                                                                            <img
                                                                                src="/placeholder.png"
                                                                                alt=""
                                                                            />
                                                                        </picture>
                                                                    </SwiperSlide>
                                                                ))}

                                                        </div>
                                                    </Swiper>
                                                </div>
                                                <CardBox realEstate={realEstate.data()} />
                                            </div>
                                        </Link>
                                        <Divider light />
                                    </div>
                                </motion.div>
                            ))
                        }
                    </div>
                </div>
                <div className="content-map">

                        <div className={`content-map-subcontainer ${darkMode ? "dark-map" : "light-map"}`} id="turkishImmoMap">
                            {posts &&
                                <>
                                    <Wrapper apiKey={apiKey}>
                                        <Map
                                            filterRealEstateType={filterRealEstateType}
                                            /*  bounds={bounds} */
                                            markersArray={markersArray}
                                            setMarkersArray={setMarkersArray}
                                            setAnchorEl={setAnchorEl}
                                            setPopoverContent={setPopoverContent}
                                            posts={posts}
                                            center={center}
                                            onIdle={onIdle}
                                            zoom={zoom}
                                            mapTypeControl={false}
                                            streetViewControl={false}
                                            gestureHandling={checkGestureHandling()}
                                            style={{ flexGrow: '1', height: '100%' }}
                                            mapId={darkMode ? 'f54dca480796bb83' : '285e7d6c676ee9f'}
                                        >
                                        </Map>
                                    </Wrapper>


                                    {
                                        popoverContent &&
                                        anchorEl &&
                                        <Popover
                                            id={id}
                                            open={open}
                                            anchorEl={anchorEl}
                                            onClose={handleClose}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'center',
                                            }}
                                            transformOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'center',
                                            }}
                                            sx={{ top: '-20px' }}
                                        >
                                            <Box className="infobox-content">
                                                {popoverContent}
                                            </Box>
                                        </Popover>
                                    }
                                </>
                            }
                        </div>

                </div>
            </div>
        </Box>
    );
}
export default RealEstateList;