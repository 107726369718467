import { useState } from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from '@mui/material/FormHelperText';
import MuiSelect from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Chip from "@mui/material/Chip";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import OutlinedInput from "@mui/material/OutlinedInput";
import TurkishProvinces from '../../turkishProvinces.json';

export default function Select(props) {

    const { name, label, error = null, multiselect } = props;
    const [locationName, setLocationName] = useState(['']);

    const handleChange = (event) => {
        const { target: { value } } = event;
        setLocationName(
            typeof value === "string" ? value.split(",") : value
        );
    };

    return (
        <FormControl sx={{ width: "100%" }}>
            <InputLabel id="label-turkish-provinces">{label}</InputLabel>
            <MuiSelect
                labelId="label-turkish-provinces"
                id="turkish-provinces"
                name={name}
                multiple={multiselect}
                value={locationName}
                onChange={handleChange}
                input={<OutlinedInput label="Provinces" />}
                renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                            <Chip key={value} label={value} />
                        ))}
                    </Box>
                )}
            >
                {TurkishProvinces.map((location) => (
                    <MenuItem key={location.value} value={location.label}>
                        <Checkbox checked={locationName.indexOf(location.label) > -1} />
                        <ListItemText primary={location.label} />
                    </MenuItem>
                ))}
            </MuiSelect>
            {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    );
}