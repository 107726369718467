import Box from '@mui/material/Box'
import LoginWithGoogle from './LoginWithGoogle'
import CheckIfUserExistInDB from './CheckIfUserExistInDB'
import Typography from '@mui/material/Typography'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from './firebaseAuth'
import { useTranslation } from 'react-i18next'

const Login = () => {

    const { t } = useTranslation();

    const [user, loading, error] = useAuthState(auth)

    return (
        <>
            {error && (
                <Box sx={{ p: 2 }}>
                    <Typography textAlign="center">{t('error')}: {error.message}</Typography>
                </Box>
            )}
            {loading && (
                <Box sx={{ p: 2 }}>
                    <Typography textAlign="center">{t('loading')}</Typography>
                </Box>
            )}
            {user && (
                <Box sx={{ p: 2 }}>
                    <CheckIfUserExistInDB user={user} />
                    <Typography textAlign="center">{t('signed_in_user')}: {user.email}</Typography>
                </Box>
            )}
            {!user && (
                <LoginWithGoogle />
            )}
        </>
    );
}

export default Login;