import { useState } from 'react'

export function useFormContacts(initialFContactValues, validateOnChange = false, validate) {

    const [contactValues, setContactValues] = useState(initialFContactValues)
    const [errors, setErrors] = useState({})

    const handleInputChange = e => {
        const { name, value } = e.target
        setContactValues({
            ...contactValues,
            [name]: value
        })
        if (validateOnChange)
            validate({ [name]: value })
    }

    const resetForm = () => {
        setContactValues(initialFContactValues)
        setErrors({})
    }

    return {
        contactValues,
        setContactValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm

    }
}

export function Form(props) {
    const { children, ...other } = props
    return (
        <form autoComplete="off" {...other}>
            {props.children}
        </form>
    )
}