import { Navigate } from 'react-router-dom'
import { useState, forwardRef } from 'react'
import { useFormCompany, Form } from '../Components/useFormCompany'
import { doc, setDoc } from 'firebase/firestore'
import { firestore } from '../firebase'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Controls from '../Components/Controls/Controls'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import Typography from '@mui/material/Typography'
import UppyFieldAvatar from '../Components/Controls/UppyFieldAvatar'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { useDocumentOnce } from 'react-firebase-hooks/firestore'

const SetCompany = ({ id, data, user }) => {

    const [openSnackbar, setOpenSnackbar] = useState(false)
    /*     const [resetUppy, setResetUppy] = useState(false) */
    const [image, setImage] = useState(data ? data.image ? data.image : [] : [])
    const rememberImage = data ? data.image : []

    const refCompany = doc(firestore, `/company/${user && user.uid}`)
    const [snapshot] = useDocumentOnce(refCompany)

    if (!id) {
        id = user.uid
    }

    let initialFValues = {}

    // like title: for a new field ask if data is setted, if yes, ask the data.title, set data.title if true, anything else empty - this is because of the data structure of the firebase database 
    initialFValues = {
        companyName: data ? data.companyName ? data.companyName : '' : '',
        companyText: data ? data.companyText ? data.companyText : '' : '',
    }

    const handleDeletePic = (key) => {
        const targetImg = document.getElementById(`avatar-element-${key}`)
        if (targetImg !== null) {
            if (targetImg.classList.contains("remove-image-element")) {
                targetImg.classList.remove("remove-image-element")
                setImage(image => [...image, rememberImage[key]])
            } else {
                targetImg.classList.add("remove-image-element")
                const name = targetImg.getAttribute("name")
                setImage(image.filter(item => item.url !== name))
            }
        }
    }

    const Alert = forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    })

    const sendCompany = async (e) => {
        await setDoc(doc(firestore, 'company', `${id}`), {
            companyName: e.companyName,
            user: user.uid,
            image: image,
            companyText: e.companyText,
        }).then(() => {
            setOpenSnackbar(true)
        }).catch((error) => {
            console.log(`Unsuccessful returned error ${error}`)
        })
    }

    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('companyName' in fieldValues)
            temp.companyName = fieldValues.companyName ? "" : "This field is required."
        setErrors({
            ...temp
        })

        if (fieldValues === values)
            return Object.values(temp).every(x => x === "")
    }

    const {
        values,
        errors,
        setErrors,
        handleInputChange,
        resetForm
    } = useFormCompany(
        initialFValues,
        true,
        validate,
        /*         resetUppy, */
        /*         setResetUppy, */
    );

    const handleSubmit = e => {
        e.preventDefault()
        if (validate()) {
            sendCompany(values)
            /*             setResetUppy(!resetUppy) */
            /*             if (!data) {
                            resetForm()
                            setImage([])
                        } */
            /*             let removeElems = document.querySelectorAll(".remove-image-element");
            
                        [].forEach.call(removeElems, function (el) {
                            el.classList.remove("remove-image-element")
                        }); */
        }
    }

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false)
    }

    return (
        <Box variant="div" sx={{
            bgcolor: 'background.default',
            color: 'text.primary',
        }}>


            {!data &&
            snapshot &&
                snapshot.data() &&
                < Navigate to={`/set-company/${user.uid}`} replace={true} />
            }

            

            {user &&
                <div className="main-container">
                    <div className="main-subcontainer">
                        <Box sx={{ p: 2 }}>
                            <Form onSubmit={handleSubmit}>
                                <Box sx={{ p: 1, display: 'flex', justifyContent: 'space-between' }}>
                                    {data &&
                                        <Typography variant="h2" mb={2}>
                                            Update company
                                        </Typography>
                                    }
                                    {!data &&
                                        <Typography variant="h2" mb={2}>
                                            Add company
                                        </Typography>
                                    }
                                </Box>
                                <Box sx={{ flexGrow: 1 }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <Controls.Input
                                                name="companyName"
                                                label="Company Name"
                                                value={values.companyName}
                                                onChange={handleInputChange}
                                                error={errors.companyName}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Controls.Textarea
                                                name="companyText"
                                                label="Company description"
                                                value={values.companyText}
                                                onChange={handleInputChange}
                                                error={errors.companyText}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="h3" mb={2}>
                                                Image
                                            </Typography>
                                            <Grid container spacing={2}>
                                                {!data ?
                                                    <Grid item xs={12} sm={6}>
                                                        <UppyFieldAvatar image={image} setImage={setImage} />
                                                    </Grid>
                                                    :
                                                    data &&
                                                    data.image &&
                                                    data.image.length === 0 &&
                                                    <Grid item xs={12} sm={6}>
                                                        <UppyFieldAvatar image={image} setImage={setImage} />
                                                    </Grid>
                                                }
                                                <Grid item xs={12} sm={6}>
                                                    {data &&
                                                        <Grid container spacing={1}>
                                                            {data.image &&
                                                                data.image.map((image, key) => (
                                                                    <Grid key={key} item xs={12}>
                                                                        <Box className={`image-box`} sx={{ position: 'relative', width: '100px' }}>
                                                                            <IconButton
                                                                                aria-label="close"
                                                                                onClick={() => handleDeletePic(key)}
                                                                                sx={{
                                                                                    position: 'absolute',
                                                                                    right: 8,
                                                                                    top: 8
                                                                                }}
                                                                            >
                                                                                <CloseIcon fontSize="small" />
                                                                            </IconButton>
                                                                            <img alt="edit pic" sx={{ fill: '#ffffff', textShadow: '2px 2px #ff0000;' }} name={image.url} id={`avatar-element-${key}`} src={image.url} />
                                                                        </Box>
                                                                    </Grid>
                                                                ))
                                                            }
                                                        </Grid>
                                                    }
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} sm={6} lg={3} sx={{ mb: 3 }}>
                                                            <Stack
                                                                direction="row"
                                                                divider={<Divider orientation="vertical" flexItem />}
                                                                spacing={2}
                                                            >
                                                                {data &&
                                                                    <>
                                                                        <Button variant="contained" type="submit" sx={{ color: '#ffffff' }}>Update</Button>
                                                                    </>
                                                                }
                                                                {!data &&
                                                                    <>

                                                                        <Button variant="contained" type="submit" sx={{ color: '#ffffff' }}>Submit</Button>
                                                                        <Button variant="contained" onClick={() => resetForm()} sx={{ color: '#ffffff' }}>Reset</Button>
                                                                    </>
                                                                }
                                                            </Stack>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Form>
                        </Box>
                    </div>
                </div>
            }
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="success">
                    The company was submitted to the server!
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default SetCompany;