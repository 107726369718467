import Input from "./Input";
import RadioGroup from "./RadioGroup";
import Select from "./Select";
import Checkbox from "./Checkbox";
import Textarea from "./Textarea";
import Switch from "./Switch";
import ProvincesSelect from "./ProvincesSelect";

const Controls = {
    Input,
    RadioGroup,
    Select,
    Checkbox,
    Textarea,
    Switch,
    ProvincesSelect
}

export default Controls;