import { Link as RouterLink, useNavigate } from 'react-router-dom'
import Link from '@mui/material/Link'
import Container from '@mui/material/Container'
import Toolbar from '@mui/material/Toolbar'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import Avatar from '@mui/material/Avatar'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useState, useCallback } from 'react'
import { auth } from './firebaseAuth'
import { signOut } from 'firebase/auth'
import Typography from '@mui/material/Typography'
import logoSmall from '../logo-small.png'
import Login from './Login'
import TuneIcon from '@mui/icons-material/Tune'
/* import Filters from './Filters' */
import Divider from '@mui/material/Divider'
import Brightness6Icon from '@mui/icons-material/Brightness6'
import Brightness7Icon from '@mui/icons-material/Brightness7'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import NavCompany from './NavCompany'
import ListItemIcon from '@mui/material/ListItemIcon'
import DomainAddIcon from '@mui/icons-material/DomainAdd'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import EuroIcon from '@mui/icons-material/Euro'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import CurrencyLiraIcon from '@mui/icons-material/CurrencyLira'
import { setGlobalState, useGlobalState } from './GlobalState'
import LanguageIcon from '@mui/icons-material/Language'
import ReactCountryFlag from 'react-country-flag'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'


const Navbar = ({ user }) => {

    /*     const [showFilters, setShowFilters] = useState(false) */

    const { t } = useTranslation();

    const [anchorElUser, setAnchorElUser] = useState(null)
    const [anchorElCurrency, setAnchorElCurrency] = useState(null)
    const [anchorElLanguage, setAnchorElLanguage] = useState(null)
    const [darkMode] = useGlobalState('darkMode')
    const [defaultCurrency] = useGlobalState('defaultCurrency')

    const defaultCurrenyIcon = (currency) => {
        if (currency === 'TRY') {
            return <CurrencyLiraIcon />
        }
        if (currency === 'EUR') {
            return <EuroIcon />
        }
        if (currency === 'USD') {
            return <AttachMoneyIcon />
        }
    }
    const defaultCurrencyIcon = defaultCurrenyIcon(defaultCurrency)
    const [currencySymbol, setCurrencySymbol] = useState(defaultCurrencyIcon)

    const handleCurrencySwitch = (currencySymbol) => {
        if (currencySymbol === 'TRY') {
            setCurrencySymbol(<CurrencyLiraIcon />)
            setGlobalState('defaultCurrency', 'TRY')
        }
        if (currencySymbol === 'EUR') {
            setCurrencySymbol(<EuroIcon />)
            setGlobalState('defaultCurrency', 'EUR')
        }
        if (currencySymbol === 'USD') {
            setCurrencySymbol(<AttachMoneyIcon />)
            setGlobalState('defaultCurrency', 'USD')
        }
        handleCloseCurrencyMenu()
    }

    /* const navigate = useNavigate(); */
    /*     const handleAddRealEstateClick = useCallback(() => navigate('/add-real-estate', { replace: true }), [navigate]) */

    const logout = () => {
        signOut(auth);
    }

    const [anchorElNav, setAnchorElNav] = useState(null);

    const handleDarkModeToggle = () => {
        setGlobalState('darkMode', !darkMode);
    }

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    }

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    }

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    }

    const handleOpenCurrencyMenu = (event) => {
        setAnchorElCurrency(event.currentTarget);
    }

    const handleCloseCurrencyMenu = () => {
        setAnchorElCurrency(null);
    }

    const handleOpenLanguageMenu = (event) => {
        setAnchorElLanguage(event.currentTarget);
    }

    const handleCloseLanguageMenu = () => {
        setAnchorElLanguage(null);
    }


    return (
        <AppBar elevation={0} position="sticky" color="secondary">
            <Container maxWidth="auto">
                <Toolbar disableGutters>
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <Link component={RouterLink} to="/">
                            <Box
                                noWrap
                                varaint="div"
                                sx={{
                                    mr: 2,
                                    display: { xs: 'none', md: 'flex' },

                                }}
                            >
                                <Box varaint="div" sx={{ maxHeight: '39px', mt: '4px' }}>
                                    <img src={logoSmall} className="logo-small" alt="Logo" />
                                </Box>
                                <Box varaint="div">
                                    <Typography
                                        variant="h5"
                                        sx={{ mb: '-10px' }}
                                    >
                                        TÜRKIYE
                                    </Typography>
                                    <Typography variant="span" sx={{ letterSpacing: '0.3px', fontWeight: 500, alignSelf: "center", fontSize: "19px" }}>
                                        Real.Estate
                                    </Typography>
                                </Box>
                            </Box>
                        </Link>
                    </Box>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        {/* <Button variant="outlined" startIcon={<TuneIcon />} onClick={() => { setShowFilters(true) }}>Filter</Button> */}
                        <Menu
                            id="filter-button"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                        </Menu>
                    </Box>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>

                        <Link component={RouterLink} to="/">
                            <img src={logoSmall} className="logo-small" alt="Logo" />
                        </Link>
                    </Box>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {/*                         <Button variant="outlined"
                            sx={{
                                ml: 2,
                                maxWidth: '200px',
                                borderColor: '#fa005e',
                                color: '#fa005e',
                                '&:hover': {
                                    borderColor: '#fa005e',
                                }
                            }}
                            startIcon={<TuneIcon />}
                            onClick={() => { setShowFilters(true) }}>
                            Filter
                        </Button> */}
                    </Box>
                    <Box sx={{ flexGrow: 0 }}>
                        <Box
                            sx={[
                                {
                                    display: 'inline-block',
                                    '&:hover': {
                                        cursor: 'pointer',
                                    },
                                },
                            ]}

                            onClick={handleOpenLanguageMenu}>
                            <ReactCountryFlag style={{ marginRight: '12px' }} countryCode={i18next.language === 'en' ? 'GB' : i18next.language.toUpperCase()} svg />
                            <Typography variant="span" sx={{ mr: 2 }}>
                               {i18next.language.toUpperCase()}
                            </Typography>
                            <Tooltip title={t('change_language')} sx={{ mr: 1 }}>
                                <IconButton>
                                    <LanguageIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <Tooltip title={t('change_currency')} sx={{ mr: 1 }}>
                            <IconButton onClick={handleOpenCurrencyMenu}>
                                {currencySymbol}
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={t('open_user_navigation')}>
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                {!user && (
                                    <Avatar alt={t('user')} src="/static/images/avatar/2.jpg" />
                                )}
                                {user && (
                                    <Avatar alt={t('user')}>
                                        <img src={user.providerData[0].photoURL} alt="" />
                                    </Avatar>
                                )}
                            </IconButton>
                        </Tooltip>

                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar-language"
                            anchorEl={anchorElLanguage}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElLanguage)}
                            onClose={handleCloseLanguageMenu}
                        >
                            <MenuItem onClick={() => { i18next.changeLanguage('en'); handleCloseLanguageMenu() }}>
                                <ReactCountryFlag style={{ marginRight: '12px' }} countryCode="GB" svg />
                                EN
                            </MenuItem>
                            <MenuItem onClick={() => { i18next.changeLanguage('de'); handleCloseLanguageMenu() }}>
                                <ReactCountryFlag style={{ marginRight: '12px' }} countryCode="DE" svg />
                                DE
                            </MenuItem>
                        </Menu>

                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar-currency"
                            anchorEl={anchorElCurrency}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElCurrency)}
                            onClose={handleCloseCurrencyMenu}
                        >
                            {defaultCurrency !== 'EUR' &&
                                <MenuItem onClick={() => handleCurrencySwitch('EUR')}>
                                    <ListItemIcon>
                                        <EuroIcon fontSize="small" />
                                    </ListItemIcon>
                                    Euro
                                </MenuItem>
                            }
                            {defaultCurrency !== 'USD' &&
                                <MenuItem onClick={() => handleCurrencySwitch('USD')}>
                                    <ListItemIcon>
                                        <AttachMoneyIcon fontSize="small" />
                                    </ListItemIcon>
                                    US Dollar
                                </MenuItem>
                            }
                            {defaultCurrency !== 'TRY' &&
                                <MenuItem onClick={() => handleCurrencySwitch('TRY')}>
                                    <ListItemIcon>
                                        <CurrencyLiraIcon fontSize="small" />
                                    </ListItemIcon>
                                    Türk lirası
                                </MenuItem>
                            }
                        </Menu>

                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            <Login anchorElUser={anchorElUser} setAnchorElUser={setAnchorElUser} />
                            <Divider light />
                            {user.admin && (
                                <MenuItem onClick={() => handleCloseUserMenu()} component={RouterLink} to={'/add-real-estate'}>
                                    <ListItemIcon>
                                        <DomainAddIcon fontSize="small" />
                                    </ListItemIcon>
                                    {t('add_a_real_estate')}
                                </MenuItem>
                            )}
                            {user && (
                                <NavCompany handleCloseUserMenu={handleCloseUserMenu} user={user} />
                            )}
                            <MenuItem onClick={handleDarkModeToggle}>
                                <ListItemIcon>
                                    {darkMode === true ?
                                        <Brightness6Icon fontSize="small" />
                                        :
                                        <Brightness7Icon fontSize="small" />
                                    }
                                </ListItemIcon>
                                <Typography textAlign="center">
                                    {darkMode === true ? t('switch_to_lightmode') : t('switch_to_darkmode')}
                                </Typography>
                            </MenuItem>
                            <Divider light />
                            {user && (
                                <MenuItem onClick={logout}>
                                    <ListItemIcon>
                                        <ExitToAppIcon fontSize="small" />
                                    </ListItemIcon>
                                    <Typography textAlign="center">{t('logout')}</Typography>
                                </MenuItem>
                            )}
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
            {/*  <Filters setShowFilters={setShowFilters} showFilters={showFilters} /> */}
            <Divider light />
        </AppBar>
    );
}

export default Navbar;