import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebase = initializeApp({
    apiKey: "AIzaSyAlffJjCpAyHGR8LM737t9Ht07ZcsFWOcY",
    authDomain: "turkishimmo-fd59c.firebaseapp.com",
    projectId: "turkishimmo-fd59c",
    storageBucket: "turkishimmo-fd59c.appspot.com",
    messagingSenderId: "135000526831",
    appId: "1:135000526831:web:59a329982cf92bf7b3701a",
    measurementId: "G-BMNZKQ5W7E"
});

export const auth = getAuth(firebase);