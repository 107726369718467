import { useState } from "react"
import RealEstateDetailsGalleryModal from './RealEstateDetailsGalleryModal'

const RealEstateDetailsGallery = ({ galleryImages, setGalleryImages, data }) => {

    const [showGallery, setShowGallery] = useState(false)
    const [initialSlide, setInitalSlide] = useState("0")

    return (
        <div className="main-container">
            <div className="main-subcontainer">
                <div className="gallery-main-container">
                    <div className="gallery-container-inside">
                        <div className="gallery-container-border">
                            <div className="gallery-height">
                                <div className="gallery-primary-container">
                                    {data.length < 5 &&
                                        <div className="gallery-secondary-container single-image">
                                            <div className="display-container-1">
                                                <div className="display-subcontainer">
                                                    <div className="display-main-container">
                                                        <button onClick={() => { setShowGallery(true); setGalleryImages(data); setInitalSlide("0"); }}>
                                                            <div className="picture-container">
                                                                {data.length < 1 ?
                                                                    <picture className="img">

                                                                        <img src="https://firebasestorage.googleapis.com/v0/b/turkishimmo-fd59c.appspot.com/o/template.jpg?alt=media&token=ba7c0355-0d27-4f8a-934e-92d87102343d" />

                                                                    </picture>
                                                                    :
                                                                    <picture className="img">
                                                                        <source srcSet={
                                                                            data[0].url + " 500w," +
                                                                            data[0].url + " 700w," +
                                                                            data[0].url + " 1200w,"
                                                                        }></source>
                                                                        <img
                                                                            src="/placeholder.png"
                                                                            className="lazyload"
                                                                            alt=""
                                                                        />
                                                                    </picture>
                                                                }



                                                                <div className="picture-inside"></div>
                                                            </div>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {data.length >= 5 &&
                                        <div className="gallery-secondary-container">
                                            <div className="display-container-1">
                                                <div className="display-subcontainer">
                                                    <div className="display-main-container">
                                                        <button onClick={() => { setShowGallery(true); setGalleryImages(data); setInitalSlide("0"); }}>
                                                            <div className="picture-container">
                                                                <picture className="img">
                                                                    <source srcSet={
                                                                        data[0].url + " 500w," +
                                                                        data[0].url + " 700w," +
                                                                        data[0].url + " 1200w,"
                                                                    }></source>
                                                                    <img
                                                                        src="/placeholder.png"
                                                                        className="lazyload"
                                                                        alt=""
                                                                    />
                                                                </picture>
                                                                <div className="picture-inside"></div>
                                                            </div>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="display-container-2">
                                                <div className="display-2-subcontainer">
                                                    <div className="display-2-tile-1">
                                                        <div className="display-main-container">
                                                            <button onClick={() => { setShowGallery(true); setGalleryImages(data); setInitalSlide("1"); }}>
                                                                <div className="picture-container">
                                                                    <picture className="img">
                                                                        <source srcSet={
                                                                            data[1].url + " 500w," +
                                                                            data[1].url + " 700w," +
                                                                            data[1].url + " 1200w,"
                                                                        }></source>
                                                                        <img
                                                                            src="/placeholder.png"
                                                                            className="lazyload"
                                                                            alt=""
                                                                        />
                                                                    </picture>
                                                                </div>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="display-2-tile-2">
                                                        <div className="display-main-container">
                                                            <button onClick={() => { setShowGallery(true); setGalleryImages(data); setInitalSlide("2") }}>
                                                                <div className="picture-container">
                                                                    <picture className="img">
                                                                        <source srcSet={
                                                                            data[2].url + " 500w," +
                                                                            data[2].url + " 700w," +
                                                                            data[2].url + " 1200w,"
                                                                        }></source>
                                                                        <img
                                                                            src="/placeholder.png"
                                                                            className="lazyload"
                                                                            alt=""
                                                                        />
                                                                    </picture>
                                                                </div>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="display-container-3">
                                                <div className="display-2-subcontainer">
                                                    <div className="display-2-tile-1">
                                                        <div className="display-main-container">
                                                            <button onClick={() => { setShowGallery(true); setGalleryImages(data); setInitalSlide("3"); }}>
                                                                <div className="picture-container">
                                                                    <picture className="img">
                                                                        <source srcSet={
                                                                            data[3].url + " 500w," +
                                                                            data[3].url + " 700w," +
                                                                            data[3].url + " 1200w,"
                                                                        }></source>
                                                                        <img
                                                                            src="/placeholder.png"
                                                                            className="lazyload"
                                                                            alt=""
                                                                        />
                                                                    </picture>
                                                                </div>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="display-2-tile-2">
                                                        <div className="display-main-container">
                                                            <button onClick={() => { setShowGallery(true); setGalleryImages(data); setInitalSlide("4"); }}>
                                                                <div className="picture-container">
                                                                    <picture className="img">
                                                                        <source srcSet={
                                                                            data[4].url + " 500w," +
                                                                            data[4].url + " 700w," +
                                                                            data[4].url + " 1200w,"
                                                                        }></source>
                                                                        <img
                                                                            src="/placeholder.png"
                                                                            className="lazyload"
                                                                            alt=""
                                                                        />
                                                                    </picture>
                                                                </div>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <RealEstateDetailsGalleryModal showGallery={showGallery} setShowGallery={setShowGallery} galleryImages={galleryImages} initialSlide={initialSlide} />
        </div>
    );
}

export default RealEstateDetailsGallery;