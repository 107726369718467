import { useGlobalState } from './../GlobalState'
import EuroIcon from '@mui/icons-material/Euro'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import CurrencyLiraIcon from '@mui/icons-material/CurrencyLira'

const CurrencyIcon = ({ iconStyle }) => {

    const [defaultCurrency] = useGlobalState('defaultCurrency')

    const currencySymbol = () => {
        switch (defaultCurrency) {
            case 'EUR':
                return <EuroIcon sx={iconStyle} />
            case 'USD':
                return <AttachMoneyIcon sx={iconStyle} />
            default:
                return <CurrencyLiraIcon sx={iconStyle} />
        }
    }

    return (currencySymbol());
}

export default CurrencyIcon;