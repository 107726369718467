import { useState } from 'react'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import { useFormContacts, Form } from '../Components/useFormContacts'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { doc, setDoc } from 'firebase/firestore'
import { firestore } from '../firebase'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import Controls from '../Components/Controls/Controls'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import UppyFieldAvatar from '../Components/Controls/UppyFieldAvatar'
import Map from '../Components/Controls/Map'

const SetContact = ({ darkMode, setOpenDeleteContactSnackbar, setOpenUpdateContactSnackbar, showUpdateContacts, setShowUpdateContacts, contact, user }) => {

    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
    const [resetUppy, setResetUppy] = useState(false)
    const [image, setImage] = useState(contact ? contact.image ? contact.image : [] : [])
    const rememberImage = contact ? contact.image ? contact.image : [] : []
    const [city, setCity] = useState(contact ? contact.city ? contact.city : '' : '')
    const [province, setProvince] = useState(contact ? contact.province ? contact.province : '' : '')
    const [lon, setLon] = useState(38.83148211203413)
    const [lat, setLat] = useState(35.38832474999999)
    const [initLat, setInitLat] = useState(contact ? contact.location ? contact.location.latidude ? contact.location.latidude : false : false : false)
    const [initLon, setInitLon] = useState(contact ? contact.location ? contact.location.longitude ? contact.location.longitude : false : false : false)

        // Gesture handling for map
        const checkGestureHandling = () => {
            if (fullScreen) {
                return 'greedy'
            } else {
                return ''
            }
        }

    const handleDeletePic = (key) => {
        const targetImg = document.getElementById(`avatar-element-${key}`)
        if (targetImg !== null) {
            if (targetImg.classList.contains("remove-image-element")) {
                targetImg.classList.remove("remove-image-element")
                setImage(image => [...image, rememberImage[key]])
            } else {
                targetImg.classList.add("remove-image-element")
                const name = targetImg.getAttribute("name")
                setImage(image.filter(item => item.url !== name))
            }
        }
    }

    let id = contact.id

    const handleClose = () => {
        setShowUpdateContacts(false)
    }

    const deleteContact = async () => {
        await setDoc(doc(firestore, 'contacts', `${id}`), {
            user: 'turkiye-real-estate',
        }).then(() => {
            setOpenDeleteContactSnackbar(true)
            handleClose()
        }).catch((error) => {
            console.log(`Unsuccessful returned error ${error}`)
        })
    }

    const initialFContactValues = {
        name: contact.name,
        email: contact.email,
        phone: contact.phone,
        whatsapp: contact.whatsapp,
        address: contact.address,
    }

    const sendContact = async (e) => {
        await setDoc(doc(firestore, 'contacts', `${id}`), {
            name: e.name,
            email: e.email,
            phone: e.phone,
            whatsapp: e.whatsapp ? e.whatsapp.replace(/[^A-Z0-9]+/ig, "") : "",
            user: user.uid,
            image: image,
            address: e.address,
            province: province,
            city: city,
            location: { "latidude": lat, "longitude": lon },
        }).then(() => {
            setOpenUpdateContactSnackbar(true)
            handleClose()
        }).catch((error) => {
            console.log(`Unsuccessful returned error ${error}`)
        })
    }

    const validate = (fieldValues = contactValues) => {
        let temp = { ...errors }
        if ('name' in fieldValues)
            temp.name = fieldValues.name ? "" : "This field is required."
        setErrors({
            ...temp
        })
        if (fieldValues === contactValues)
            return Object.values(temp).every(x => x === "")
    }

    const {
        contactValues,
        errors,
        setErrors,
        handleInputChange
    } = useFormContacts(initialFContactValues, true, validate)

    const handleSubmit = e => {
        e.preventDefault()
        if (validate()) {
            sendContact(contactValues)
            setResetUppy(!resetUppy)
        }
    }

    return (
        <Dialog
            fullScreen={fullScreen}
            maxWidth='lg'
            fullWidth={true}
            open={showUpdateContacts}
            onClose={handleClose}
        >
            <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between', borderBottom: 1, borderColor: 'divider' }}>
                <Typography variant="h2">
                    Update Contact
                </Typography>
                <Box>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Box>
            <DialogContent>
                <Form id="setUpdateContact" onSubmit={handleSubmit}>
                    <Box sx={{ p: 2 }}>
                        <Grid container sx={{ mb: 3 }} spacing={2}>
                            {contact.image && 
                            contact.image.length === 0 &&
                                <Grid item xs={12} sm={6}>
                                    <UppyFieldAvatar darkMode={darkMode} resetUppy={resetUppy} image={image} setImage={setImage} />
                                </Grid>
                            }
                            <Grid item xs={12} sm={6}>
                                {contact &&
                                    <Grid container spacing={1}>
                                        {contact.image &&
                                            contact.image.map((image, key) => (
                                                <Grid key={key} item xs={12}>
                                                    <Box className={`image-box`} sx={{ position: 'relative', width: '100px' }}>
                                                        <IconButton
                                                            aria-label="close"
                                                            onClick={() => handleDeletePic(key)}
                                                            sx={{
                                                                position: 'absolute',
                                                                right: 8,
                                                                top: 8
                                                            }}
                                                        >
                                                            <CloseIcon fontSize="small" />
                                                        </IconButton>
                                                        <img alt="edit pic" sx={{ fill: '#ffffff', textShadow: '2px 2px #ff0000;' }} name={image.url} id={`avatar-element-${key}`} src={image.url} />
                                                    </Box>
                                                </Grid>
                                            ))
                                        }
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} lg={3}>
                                <Controls.Input
                                    name="name"
                                    label="Name"
                                    value={contactValues.name}
                                    onChange={handleInputChange}
                                    error={errors.name}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} lg={3}>
                                <Controls.Input
                                    name="email"
                                    label="Email"
                                    type="email"
                                    value={contactValues.email}
                                    onChange={handleInputChange}
                                    error={errors.email}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} lg={3}>
                                <Controls.Input
                                    name="phone"
                                    label="Phone number"
                                    type="tel"
                                    value={contactValues.phone}
                                    onChange={handleInputChange}
                                    error={errors.phone}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} lg={3}>
                                <Controls.Input
                                    name="whatsapp"
                                    label="WhatsApp Phone Number"
                                    type="tel"
                                    value={contactValues.whatsapp}
                                    onChange={handleInputChange}
                                    error={errors.whatsapp}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} lg={3}>
                                <Controls.Textarea
                                    name="address"
                                    label="Address"
                                    value={contactValues.address}
                                    onChange={handleInputChange}
                                    error={errors.address}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Map
                                    initLat={initLat}
                                    initLon={initLon}
                                    setInitLat={setInitLat}
                                    setInitLon={setInitLon}
                                    setLon={setLon}
                                    setLat={setLat}
                                    setCity={setCity}
                                    setProvince={setProvince}
                                    mapOptions={{
                                        center: { lat: 39.445007, lng: 34.157856 },
                                        zoom: 6,
                                        mapTypeControl: false,
                                        streetViewControl: false,
                                        gestureHandling: checkGestureHandling(),
                                        mapId: darkMode ? 'f54dca480796bb83' : '285e7d6c676ee9f'
                                    }}
                                    id="map-select-contact" />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Controls.Input
                                    disabled={true}
                                    name="province"
                                    label="Province"
                                    value={province}
                                    onChange={handleInputChange}
                                    error={errors.province}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Controls.Input
                                    disabled={true}
                                    name="city"
                                    label="City"
                                    value={city}
                                    onChange={handleInputChange}
                                    error={errors.city}
                                />
                            </Grid>


                        </Grid>
                    </Box>
                </Form>
            </DialogContent>
            <DialogActions>
                <Stack
                    direction="row"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={2}
                >
                    <Button type="submit" form="setUpdateContact">
                        Update Data
                    </Button>
                    <Button onClick={() => { deleteContact() }}>Delete Contact</Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
}

export default SetContact;