import { forwardRef } from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

export const Item = forwardRef(({ id, image, num, handleDeletePic, ...props }, ref) => {

    return (
        <div {...props} ref={ref}>
            <img alt="edit pic" style={{ maxWidth: '150px', fill: '#ffffff', textShadow: '2px 2px #ff0000', marginRight: '4px' }} name={image.url} id={`image-element-${num}`} src={image.url} />
        </div>
    )
});

export function SortableItem(props) {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id: props.id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <Item num={props.num} image={props.id} ref={setNodeRef} style={style} {...attributes} {...listeners}>

        </Item>
    );
}