import { useSignInWithGoogle } from 'react-firebase-hooks/auth'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import { auth } from './firebaseAuth'

const LoginWithGoogle = () => {

    const [signInWithGoogle] = useSignInWithGoogle(auth);

    return (
        <MenuItem onClick={() => signInWithGoogle()}>
            <Typography textAlign="center">Login with Google</Typography>
        </MenuItem>
    );
}

export default LoginWithGoogle;