import Dialog from '@mui/material/Dialog'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import DialogContent from '@mui/material/DialogContent'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import 'swiper/css/bundle'
import { useGlobalState } from '../GlobalState'

const RealEstateDetailsGalleryModal = ({ showGallery, setShowGallery, galleryImages, initialSlide }) => {

    const darkMode = useGlobalState('darkMode')

/*     console.log(darkMode) */

    const theme = useTheme()

    const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

    const handleClose = () => {
        setShowGallery(false);
    };

    return (
        <Dialog
            fullScreen={fullScreen}
            maxWidth='lg'
            open={showGallery}
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            sx={{ overflow: 'hidden', pb: 0, margin: 0, width: '100%' }}
        >
            <DialogContent sx={{ overflow: 'hidden', mt: 6, mr: 0, ml: 0, p: 0, mb: 0 }}>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 4,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <Box className={`gallery-swiper ${darkMode ? 'darkMode' : 'lightMode'}`}>
                    <Swiper
                        modules={[Navigation]}
                        slidesPerView={1}
                        spaceBetween={16}
                        initialSlide={initialSlide}
                        navigation
                    >
                        {galleryImages && (
                            galleryImages.map((image, i) =>
                                <SwiperSlide obj={galleryImages} key={i}>
                                    <picture className="img">
                                        <source srcSet={
                                            image.url + " 500w," +
                                            image.url + " 700w," +
                                            image.url + " 1200w,"
                                        }></source>
                                        <img
                                            src="/placeholder.png"
                                            className="lazyload"
                                            alt=""
                                        />
                                    </picture>
                                </SwiperSlide>
                            )
                        )}
                    </Swiper>
                </Box>
            </DialogContent>
        </Dialog>
    );
}

export default RealEstateDetailsGalleryModal;