import { firestore } from '../firebase'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { doc } from 'firebase/firestore'
import UpdateContactRelation from './UpdateContactRelation'

const postConverter = {
    fromFirestore(
        snapshot,
        options
    ) {
        const data = snapshot.data(options)
        return {
            ...data,
            id: snapshot.id
        }
    },
}

const UpdateContacts = ({ darkMode, setOpenDeleteContactSnackbar, setOpenUpdateContactSnackbar, user, showUpdateContacts, setShowUpdateContacts, contactData }) => {

    const ref = doc(firestore, `/contacts/${contactData.id}`).withConverter(postConverter)

    const [contact/* , loading, error, snapshot */] = useDocumentData(ref)

    return (
        <>
            {contact &&
                <UpdateContactRelation setOpenDeleteContactSnackbar={setOpenDeleteContactSnackbar} darkMode={darkMode} setOpenUpdateContactSnackbar={setOpenUpdateContactSnackbar} user={user} contact={contact} showUpdateContacts={showUpdateContacts} setShowUpdateContacts={setShowUpdateContacts} />
            }
        </>
    );
}

export default UpdateContacts;