import { doc } from 'firebase/firestore'
import { firestore } from './../firebase'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import numberWithDot from './../numberWithDot'
import { useGlobalState } from './../GlobalState'

const CalculatePrice = ({price}) => {

    const [defaultCurrency] = useGlobalState('defaultCurrency')

    const docRef = doc(firestore, 'currency', 'exchangeValue')

    const [dataCurrency/* , loading, error, snapshot */] = useDocumentData(docRef)
  
    const calculatePrice = (price, currency) => {
        if (currency === 'TRY') {
          return Number(price) * 1
        } else {
          return Number(price) * Number(dataCurrency.resultFromApi.rates[currency])
        }
      }

    return dataCurrency &&
    numberWithDot(Number(calculatePrice(price, defaultCurrency)).toFixed(0))
}
 
export default CalculatePrice;