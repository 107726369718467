import ContactModal from '../Contacts/ContactModal'
import Box from '@mui/material/Box'
import SendIcon from '@mui/icons-material/Send'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import RealEstateCompany from './RealEstateCompany'
import Typography from '@mui/material/Typography'
import CalculatePrice from '../Currency/CalculatePrice'
import CurrencyIcon from '../Currency/CurrencyIcon'
import { useTranslation } from 'react-i18next'

const DetailActionCard = ({showCardOnTop, createdBy, price, monthlyFee, marketingTypeGroup, checkedContacts, setShowContactModal, showContactModal}) => {
    
    const { t } = useTranslation();
    
    return ( 
        <Card variant="outlined" sx={{ borderRadius: '12px', display: showCardOnTop }}>
        <Box sx={{ pl: 2, pr: 2, pb: 2 }}>
          <CardContent>
            <Typography variant="h6" component="div" sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
              <Box>
                <CalculatePrice price={price} />
                <CurrencyIcon iconStyle={{ fontSize: '18px', position: 'relative', top: '2px', mr: 1 }} />
              </Box>
              <Box>
                {marketingTypeGroup === 'rent' && t('per_month')}
              </Box>
            </Typography>
            {monthlyFee !== 0 &&
              < Typography sx={{ mb: 1.5, display: 'flex', alignItems: 'center' }} color="text.secondary">
                {t('monthly_fee')}: <CalculatePrice price={monthlyFee} /> <CurrencyIcon iconStyle={{ fontSize: '18px', mr: 1 }} />
              </Typography>
            }
            <Box sx={{ mt: 2 }}>
              <RealEstateCompany createdBy={createdBy} />
            </Box>
          </CardContent>
          <CardActions>
            <Button onClick={() => setShowContactModal(true)} variant="contained" sx={{ fontWeight: 600 }} endIcon={<SendIcon />}>
              {t('contact')}
            </Button>
            <ContactModal contactData={checkedContacts} setShowContactModal={setShowContactModal} showContactModal={showContactModal} />
          </CardActions>
        </Box>
      </Card>
     );
}
 
export default DetailActionCard;