import { useParams, Link } from 'react-router-dom'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import DetailFeatures from './DetailFeatures'
import Typography from '@mui/material/Typography'
import BorderInnerIcon from '@mui/icons-material/BorderInner'
import BorderOuterIcon from '@mui/icons-material/BorderOuter'
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom'
import KitchenIcon from '@mui/icons-material/Kitchen'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'
import LocationCityIcon from '@mui/icons-material/LocationCity'
import BedIcon from '@mui/icons-material/Bed'
import ShowerIcon from '@mui/icons-material/Shower'
import RealEstateDetailsGallery from './RealEstateDetailsGallery'
import 'lazysizes/plugins/attrchange/ls.attrchange'
import { doc } from 'firebase/firestore'
import { firestore } from './../firebase'
import estateTypeName from './estateTypeName'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import DetailGallery from './DetailGallery'
import Divider from '@mui/material/Divider'
import RealEstateDetailMap from './RealEstateDetailMap'
import { useState } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import Footer from '../Footer'
import { useGlobalState } from '../GlobalState'
import Skeleton from '@mui/material/Skeleton'
import { motion } from 'framer-motion'
import DetailActionCard from './DetailActionCard'
import { useTranslation } from 'react-i18next'

const postConverter = {
  fromFirestore(
    snapshot,
    options
  ) {
    const data = snapshot.data(options);
    return {
      ...data,
      id: snapshot.id,
      /*       ref: snapshot.ref */
    };
  },
};

const RealEstateDetails = ({ user }) => {

  const { id } = useParams();
  const [first/* , ...rest */] = id.split('-')

  const [galleryImages, setGalleryImages] = useState(false)
  const [showContactModal, setShowContactModal] = useState(false)
  const [darkMode] = useGlobalState('darkMode')

  const { t } = useTranslation();

  /*   const [user] = useAuthState(auth) */

  const ref = doc(firestore, `/realEstates/${first}`).withConverter(postConverter)

  const [data/* , loading, error, snapshot */] = useDocumentData(ref)

  const matchesMq = useMediaQuery('(min-width:600px)')

  let showCardOnTop = matchesMq ? 'none' : 'block'
  let showCardOnSide = matchesMq ? 'block' : 'none'

  return (
    <Box className="real-estate-details"
      sx={{
        bgcolor: 'background.default',
        color: 'text.primary'
      }}>


      <article>
        <div className="main-container">
          <div className="main-subcontainer">
            {data ?
              <Box sx={{ mt: 3 }}>
                <Typography variant="h1">
                  {data.title}
                </Typography>
                <p>{data.city}, {data.province}, {data.country}</p>
              </Box>
              :
              <Box sx={{ pt: 3 }}>
                <Skeleton />
                <Skeleton width="60%" />
              </Box>
            }
          </div>
        </div>


        {data ?
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{
              type: "spring",
              stiffness: 260,
              damping: 23
            }}
          >
            <RealEstateDetailsGallery 
            galleryImages={galleryImages} 
            setGalleryImages={setGalleryImages} 
            data={data.images} />
          </motion.div>
          :
          <div className="main-container">
            <div className="main-subcontainer">
              <Box>
                <Skeleton sx={{ borderRadius: '20px', marginBottom: '20px', marginTop: '20px' }} variant="rectangular" height={500} />
              </Box>
            </div>
          </div>
        }

        <div className="main-container">
          <div className="main-subcontainer">

            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={{ xs: 6, md: 8, lg: 12 }}>
                <Grid item xs={12} sm={7} lg={8}>
                  <div className="detail-header">
                    <Grid
                      container
                      direction="row"
                    >
                      <Grid item xs={10}>

                        {data ?
                          <Typography variant="h2" sx={{ pr: 2 }}>
                            {data.subTitle}
                          </Typography>
                          :
                          <Box sx={{ pt: 0.5 }}>
                            <Skeleton />
                          </Box>
                        }


                      </Grid>
                      {user &&
                        <Grid item xs={2}>
                          {data && data.user.trim() === user.uid.trim() &&
                            <Button component={Link} to={`/add-real-estate/${data.id}`} sx={{ float: 'right' }} variant="outlined" size="small">
                              {t('edit')}
                            </Button>
                          }
                        </Grid>
                      }
                    </Grid>

                    {data ?
                      <Box variant="div" sx={{ pb: 3 }}>
                        {data.marketingTypeGroup.charAt(0).toUpperCase() + data.marketingTypeGroup.substring(1)} {data.realEstateType === "apartment" ? "an" : "a"} {estateTypeName(data.realEstateType)} in {data.city} ({data.province})
                      </Box>
                      :
                      <Box sx={{ pt: 0.5 }}>
                        <Skeleton width="60%" />
                        <Skeleton width="60%" />
                        <Skeleton width="60%" />
                        <Skeleton width="60%" />
                        <Skeleton width="60%" />
                        <Skeleton width="60%" />
                        <Skeleton width="60%" />
                        <Skeleton width="60%" />
                        <Skeleton width="60%" />
                        <Skeleton width="60%" />
                        <Skeleton width="60%" />
                        <Skeleton width="60%" />
                      </Box>
                    }
                    <Divider light />
                    {data &&
                      <DetailActionCard
                        createdBy={data.user}
                        price={data.price}
                        monthlyFee={data.monthlyFee}
                        marketingTypeGroup={data.marketingTypeGroup}
                        checkedContacts={data.checkedContacts}
                        setShowContactModal={setShowContactModal}
                        showContactModal={showContactModal}
                        showCardOnTop={showCardOnTop}
                      />
                    }
                  </div>
                  {data &&
                    <div>
                      <Stack
                        direction={{ md: 'row' }}

                        sx={{ mb: "20px", mt: 3, flexFlow: 'row wrap', justifyContent: 'space-evenly' }}
                        className="counter-list"
                      >
                        {data.squaremeterGross !== 0 &&
                          <Box sx={{ mb: 1, pr: '4px', pl: '4px' }}>
                            <Chip label={`${data.squaremeterGross} ${t('squaremeter_gross')}`} icon={<BorderOuterIcon />} />
                          </Box>
                        }
                        {data.squaremeterNet !== 0 &&
                          <Box sx={{ mb: 1, pr: '4px', pl: '4px' }}>
                            <Chip label={`${data.squaremeterNet} ${t('squaremeter_net')}`} icon={<BorderInnerIcon />} />
                          </Box>
                        }
                        {data.rooms.total !== 0 &&
                          <Box sx={{ mb: 1, pr: '4px', pl: '4px' }}>
                            <Chip label={`${data.rooms.total} ${t('rooms_in_total')}`} icon={<MeetingRoomIcon />} />
                          </Box>
                        }
                        {data.rooms.kitchens !== 0 &&
                          <Box sx={{ mb: 1, pr: '4px', pl: '4px' }}>
                            <Chip label={`${data.rooms.kitchens} ${t('kitchens')}`} icon={<KitchenIcon />} />
                          </Box>
                        }
                        {data.rooms.kitchens !== 0 &&
                          <Box sx={{ mb: 1, pr: '4px', pl: '4px' }}>
                            <Chip label={`${data.rooms.bedrooms} ${t('bedrooms')}`} icon={<BedIcon />} />
                          </Box>
                        }
                        {data.rooms.kitchens !== 0 &&
                          <Box sx={{ mb: 1, pr: '4px', pl: '4px' }}>
                            <Chip label={`${data.rooms.bathrooms}  ${t('bathrooms')}`} icon={<ShowerIcon />} />
                          </Box>
                        }
                        {data.parkingSpots !== 0 &&
                          <Box sx={{ mb: 1, pr: '4px', pl: '4px' }}>
                            <Chip label={`${data.parkingSpots}  ${t('parking_spots')}`} icon={<DirectionsCarIcon />} />
                          </Box>
                        }
                        {data.parkingSpots !== 0 &&
                          <Box sx={{ mb: 1, pr: '4px', pl: '4px' }}>
                            <Chip label={`${t('real_estate_is_on_floor')} ${data.parkingSpots}`} icon={<LocationCityIcon />} />
                          </Box>
                        }
                      </Stack>
                    </div>
                  }
                  <Divider light />
                  {data &&
                    <>
                      <Typography variant="body1" sx={{ mt: 3, mb: 3 }} paragraph>
                        {data.body}
                      </Typography>
                      <Divider light />
                      <DetailFeatures featureObject={data.features} />
                    </>
                  }
                </Grid>
                {data &&
                  <Grid item xs={12} sm={5} lg={4} sx={{ mb: 3, display: showCardOnSide }}>
                    <Box className="info-slide-container">
                      <DetailActionCard
                        createdBy={data.user}
                        price={data.price}
                        monthlyFee={data.monthlyFee}
                        marketingTypeGroup={data.marketingTypeGroup}
                        checkedContacts={data.checkedContacts}
                        setShowContactModal={setShowContactModal}
                        showContactModal={showContactModal}
                      />
                    </Box>
                  </Grid>
                }
              </Grid>
              {data && data.images.length > 3 &&
                <>
                  <Divider light />
                  <Box sx={{ mt: 3, mb: 3 }}>
                    <Typography variant="h3" sx={{ mb: 3 }}>{t('gallery')}</Typography>
                    <DetailGallery data={data.images} />
                  </Box>
                </>
              }
              <Divider light />
              {data &&
                <Box sx={{ mt: 3, mb: 3 }}>
                  <Typography variant="h3" sx={{ mb: 3 }}>{t('map')}</Typography>
                  <RealEstateDetailMap
                    mapOptions={{
                      center: { lat: data.location.latidude, lng: data.location.longitude },
                      zoom: 12,
                      mapTypeControl: false,
                      gestureHandling: 'cooperative',
                      mapId: darkMode ? 'f54dca480796bb83' : '285e7d6c676ee9f'
                    }}
                    data={data.location}
                    id="turkishRealEstateDetailMap"
                  />
                </Box>
              }
            </Box>
          </div>
        </div>
      </article>
      <Footer />
    </Box >
  );
}

export default RealEstateDetails;