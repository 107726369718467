import { useMemo, useEffect } from 'react'
import Uppy from '@uppy/core'
import Transloadit from '@uppy/transloadit'
import { Dashboard } from '@uppy/react'
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import { useGlobalState } from '../../GlobalState'

const UppyFieldAvatar = ({ setImage, image, resetUppy }) => {

    const [darkMode] = useGlobalState('darkMode')

    const uppy = useMemo(() => {
        return new Uppy({
            restrictions: {
                maxNumberOfFiles: 1,
                maxFileSize: 3000000,
                allowedFileTypes: [
                    ".jpg",
                    ".jpeg",
                    ".png",
                    ".JPG",
                    ".JPEG",
                    ".PNG"
                ]
            }
        })
            .use(Transloadit, {
                id: 'transloaditImmoAvatar',
                params: {
                    auth: {
                        key: 'e2321a869fbf409e89e20f15417c9bb3'
                    },
                    steps: {
                        resize: {
                            robot: '/image/resize',
                            width: 100,
                            height: 100,
                            resize_strategy: 'crop',
                        },
                        exported: {
                            use: [
                                "resize"
                            ],
                            robot: "/google/store",
                            credentials: "Google Storage Credentials"
                        }
                    }
                },
                waitForEncoding: true
            })
            .on('transloadit:result', (stepName, result) => {
                setImage(image => [...image, { url: result.ssl_url }]);
                /* images.indexOf(result.ssl_url) === -1 ? images.push(result.ssl_url) : console.log("This item already exists"); */
                /*     console.log(stepName) */
                /*     const file = uppy.getFile(result.localId)
                    var resultContainer = document.createElement('div')
                    resultContainer.innerHTML = `
                      <div>
                        <h3>Name: ${file.name}</h3>
                        <img src="${result.ssl_url}" /> <br />
                        <a href="${result.ssl_url}">View</a>
                      </div>
                    `
                    console.log(resultContainer) */
                /*     document
                      .getElementById('uppy-transloadit-result'); */
            })
    }, [setImage])

    useEffect(() => {
        return () => uppy.reset(true)
    }, [resetUppy])

    useEffect(() => {
        return () => uppy.close({ reason: 'unmount' })
    }, [uppy])

    return (
        <Dashboard
            note="Avatar image only (jpg or png), up to 3 MB per file. Best results in 1:1 format."
            uppy={uppy}
            doneButtonHandler={null}
            id="uppyFieldAvatar"
            theme={darkMode ? 'dark' : 'light'}
            height={403}
            proudlyDisplayPoweredByUppy={false}
            locale={{
                strings: {
                    dropHereOr: 'Drop here or %{browse}',
                    browse: 'browse',
                },
            }}
        />
    )
}

export default UppyFieldAvatar;