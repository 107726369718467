import { useState, useEffect, forwardRef } from 'react'
import { useForm, Form } from '../../Components/useForm'
import { doc, setDoc } from 'firebase/firestore'
import firebase from 'firebase/compat/app'
import { firestore } from '../../firebase'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Controls from '../../Components/Controls/Controls'
import Map from '../../Components/Controls/Map'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import Typography from '@mui/material/Typography'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import UppyField from '../../Components/Controls/UppyField'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../../firebaseAuth'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Contact from './Contact'
import useMediaQuery from '@mui/material/useMediaQuery'
import SetContact from '../../Contacts/SetContact'
import { query, where, collection } from 'firebase/firestore'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import UpdateContacts from '../../Contacts/UpdateContacts'
import { useGlobalState } from '../../GlobalState'
import * as geofire from 'geofire-common'

import {
    closestCenter,
    DndContext,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    rectSortingStrategy
} from '@dnd-kit/sortable';

import { SortableItem } from './SortableItem';


const postConverter = {
    fromFirestore(
        snapshot,
        options
    ) {
        const data = snapshot.data(options);
        return {
            ...data,
            id: snapshot.id,
            /*       ref: snapshot.ref */
        };
    },
};

const AddRealEstate = ({ data, id, user }) => {

/*     const [activeId, setActiveId] = useState(null);
    const [items, setItems] = useState(['1', '2', '3']); */
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );


/*     const [user] = useAuthState(auth) */
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [contactData, setContactData] = useState('')
    const [priceLabel, setPriceLabel] = useState('Price in TL')
    const [resetUppy, setResetUppy] = useState(false)
    const [expanded, setExpanded] = useState(false)
    const [lon, setLon] = useState(38.83148211203413)
    const [lat, setLat] = useState(35.38832474999999)
    const [initLat, setInitLat] = useState(data ? data.location.latidude : false)
    const [initLon, setInitLon] = useState(data ? data.location.longitude : false)
    const [city, setCity] = useState(data ? data.city : '')
    const [province, setProvince] = useState(data ? data.province : '')
    const [images, setImages] = useState(data ? data.images : [])
    const [imagesToRemove, setImagesToRemove] = useState([])
    const rememberImages = data ? data.images : []
    const [showContact, setShowContact] = useState(false)
    const [showUpdateContacts, setShowUpdateContacts] = useState(false)
    const [openContactSnackbar, setOpenContactSnackbar] = useState(false)
    const [openUpdateContactSnackbar, setOpenUpdateContactSnackbar] = useState(false)
    const [checkedContacts, setCheckedContacts] = useState(data ? data.checkedContacts : [0])
    const [openContactFailSnackbar, setOpenContactFailSnackbar] = useState(false)
    const [openDeleteContactSnackbar, setOpenDeleteContactSnackbar] = useState(false)
    const [darkMode] = useGlobalState('darkMode')

    const q = query(
        collection(firestore, "contacts"),
        /*         limit(3), */
        where("user", "==", user.uid)
    ).withConverter(postConverter)

    const [allContacts /* , loading, error */] = useCollectionData(q)

    // Gesture handling for map
    const matches = useMediaQuery('(min-width:600px)')
    const checkGestureHandling = () => {
        if (matches) {
            return 'greedy'
        } else {
            return ''
        }
    }

    let initialFValues = {}

    if (!id) {
        id = Date.now()
    }

    // like title: for a new field ask if data is setted, if yes, ask the data.title, set data.title if true, anything else empty - this is because of the data structure of the firebase database 
    initialFValues = {
        title: data ? data.title ? data.title : '' : '',
        subTitle: data ? data.subTitle ? data.subTitle : '' : '',
        active: data ? data.active ? data.active : true : true,
        body: data ? data.body ? data.body : '' : '',
        price: data ? data.price ? data.price : 0 : 0,
        monthlyFee: data ? data.monthlyFee ? data.monthlyFee : 0 : 0,
        marketingTypeGroup: data ? data.marketingTypeGroup ? data.marketingTypeGroup : '' : '',
        realEstateType: data ? data.realEstateType : '',
        humanReadableUrl: data ? data.humanReadableUrl : '',
        city: data ? data.city : city,
        province: data ? data.city : province,
        adsl: data ? data.features.innerFeatures.adsl : false,
        wlan: data ? data.features.innerFeatures.wlan : false,
        fiberOpticInternet: data ? data.features.innerFeatures.fiberOpticInternet : false,
        kitchen: data ? data.features.innerFeatures.kitchen : false,
        bath: data ? data.features.innerFeatures.bath ? data.features.innerFeatures.bath : false : false,
        oven: data ? data.features.innerFeatures.oven ? data.features.innerFeatures.oven : false : false,
        stove: data ? data.features.innerFeatures.stove ? data.features.innerFeatures.stove : false : false,
        intercom: data ? data.features.innerFeatures.intercom : false,
        basement: data ? data.features.innerFeatures.basement : false,
        gasConnection: data ? data.features.innerFeatures.gasConnection : false,
        chimney: data ? data.features.innerFeatures.chimney : false,
        washingMachine: data ? data.features.innerFeatures.washingMachine : false,
        heating: data ? data.features.innerFeatures.heating : false,
        innerElevator: data ? data.features.innerFeatures.innerElevator : false,
        disabledAccess: data ? data.features.innerFeatures.disabledAccess : false,
        cableTelevision: data ? data.features.innerFeatures.cableTelevision : false,
        dishwasher: data ? data.features.innerFeatures.dishwasher : false,
        airCondition: data ? data.features.innerFeatures.airCondition : false,
        furniture: data ? data.features.innerFeatures.furniture : false,
        blinds: data ? data.features.innerFeatures.blinds : false,
        steelDoor: data ? data.features.innerFeatures.steelDoor : false,
        terrace: data ? data.features.innerFeatures.terrace : false,
        alarmSystem: data ? data.features.innerFeatures.alarmSystem : false,
        fireAlarm: data ? data.features.innerFeatures.fireAlarm : false,
        innerSauna: data ? data.features.innerFeatures.innerSauna : false,
        balcony: data ? data.features.innerFeatures.balcony : false,
        whirlpool: data ? data.features.innerFeatures.whirlpool : false,
        hSecurityService: data ? data.features.outerFeatures.hSecurityService : false,
        tennisCourt: data ? data.features.outerFeatures.tennisCourt : false,
        footballField: data ? data.features.outerFeatures.footballField : false,
        basketballCourt: data ? data.features.outerFeatures.basketballCourt : false,
        cameraMonitoring: data ? data.features.outerFeatures.cameraMonitoring : false,
        parkingSpotOpen: data ? data.features.outerFeatures.parkingSpotOpen : false,
        parkingSpotClosed: data ? data.features.outerFeatures.parkingSpotClosed : false,
        undergroundCarPark: data ? data.features.outerFeatures.undergroundCarPark : false,
        emergencyExit: data ? data.features.outerFeatures.emergencyExit : false,
        playground: data ? data.features.outerFeatures.playground : false,
        outerElevator: data ? data.features.outerFeatures.outerElevator : false,
        dieselGenerator: data ? data.features.outerFeatures.dieselGenerator : false,
        doorman: data ? data.features.outerFeatures.doorman : false,
        outerSauna: data ? data.features.outerFeatures.outerSauna : false,
        pool: data ? data.features.outerFeatures.pool : false,
        seaView: data ? data.features.outerFeatures.seaView : false,
        mountainView: data ? data.features.outerFeatures.mountainView : false,
        streetView: data ? data.features.outerFeatures.streetView : false,
        bosporusView: data ? data.features.outerFeatures.bosporusView : false,
        beach: data ? data.features.neighborhood.beach : false,
        shoppingMall: data ? data.features.neighborhood.shoppingMall : false,
        kindergarten: data ? data.features.neighborhood.kindergarten : false,
        school: data ? data.features.neighborhood.school : false,
        highSchool: data ? data.features.neighborhood.highSchool : false,
        hospital: data ? data.features.neighborhood.hospital : false,
        mosque: data ? data.features.neighborhood.mosque : false,
        pharmacy: data ? data.features.neighborhood.pharmacy : false,
        university: data ? data.features.neighborhood.university : false,
        market: data ? data.features.neighborhood.market : false,
        fireDepartment: data ? data.features.neighborhood.fireDepartment : false,
        park: data ? data.features.neighborhood.park : false,
        gym: data ? data.features.neighborhood.gym : false,
        busStop: data ? data.features.neighborhood.busStop : false,
        highway: data ? data.features.neighborhood.highway : false,
        tram: data ? data.features.neighborhood.tram : false,
        subway: data ? data.features.neighborhood.subway : false,
        trainStation: data ? data.features.neighborhood.trainStation : false,
        airport: data ? data.features.neighborhood.airport : false,
        north: data ? data.features.frontside.north : false,
        east: data ? data.features.frontside.east : false,
        south: data ? data.features.frontside.south : false,
        west: data ? data.features.frontside.west : false,
        rooms: data ? data.rooms.total : 0,
        kitchens: data ? data.rooms.kitchens : 0,
        bathrooms: data ? data.rooms.bathrooms : 0,
        bedrooms: data ? data.rooms.bedrooms : 0,
        squaremeterGross: data ? data.squaremeterGross : 0,
        squaremeterNet: data ? data.squaremeterNet : 0,
        floor: data ? data.floor : 0,
        parkingSpots: data ? data.parkingSpots : 0,
    }

    const handleAccordeonChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false)
    }

    const Alert = forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    })

    const sendNewEstate = async (e) => {
   /*      console.log(images) */
        await setDoc(doc(firestore, 'realEstates', `${id}`), {
            id: id,
            title: e.title,
            subTitle: e.subTitle,
            active: e.active,
            user: user.uid,
            humanReadableUrl: e.title.replace(/[^a-z0-9_]+/gi, '-').replace(/^-|-$/g, '').toLowerCase(),
            body: e.body,
            price: Number(e.price) ? Number(e.price) : 0,
            monthlyFee: Number(e.monthlyFee) ? Number(e.monthlyFee) : 0,
            marketingTypeGroup: e.marketingTypeGroup,
            realEstateType: e.realEstateType,
            city: city,
            province: province,
            country: 'Turkey',
            rooms: {
                "total": Number(e.rooms) ? Number(e.rooms) : 0,
                "kitchens": Number(e.kitchens) ? Number(e.kitchens) : 0,
                "bathrooms": Number(e.bathrooms) ? Number(e.bathrooms) : 0,
                "bedrooms": Number(e.bedrooms) ? Number(e.bedrooms) : 0,
            },
            squaremeterGross: Number(e.squaremeterGross) ? Number(e.squaremeterGross) : 0,
            squaremeterNet: Number(e.squaremeterNet) ? Number(e.squaremeterNet) : 0,
            floor: Number(e.floor) ? Number(e.floor) : 0,
            parkingSpots: Number(e.parkingSpots) ? Number(e.parkingSpots) : 0,
            features:
            {
                "innerFeatures": {
                    "adsl": e.adsl,
                    "wlan": e.wlan,
                    "fiberOpticInternet": e.fiberOpticInternet,
                    "kitchen": e.kitchen,
                    "bath": e.bath,
                    "oven": e.oven,
                    "stove": e.stove,
                    "intercom": e.intercom,
                    "basement": e.basement,
                    "gasConnection": e.gasConnection,
                    "chimney": e.chimney,
                    "washingMachine": e.washingMachine,
                    "heating": e.heating,
                    "innerElevator": e.innerElevator,
                    "disabledAccess": e.disabledAccess,
                    "cableTelevision": e.cableTelevision,
                    "dishwasher": e.dishwasher,
                    "airCondition": e.airCondition,
                    "furniture": e.furniture,
                    "blinds": e.blinds,
                    "steelDoor": e.steelDoor,
                    "terrace": e.terrace,
                    "alarmSystem": e.alarmSystem,
                    "fireAlarm": e.fireAlarm,
                    "innerSauna": e.innerSauna,
                    "balcony": e.balcony,
                    "whirlpool": e.whirlpool,
                },
                "outerFeatures": {
                    "hSecurityService": e.hSecurityService,
                    "tennisCourt": e.tennisCourt,
                    "footballField": e.footballField,
                    "basketballCourt": e.basketballCourt,
                    "cameraMonitoring": e.cameraMonitoring,
                    "parkingSpotOpen": e.parkingSpotOpen,
                    "parkingSpotClosed": e.parkingSpotClosed,
                    "undergroundCarPark": e.undergroundCarPark,
                    "emergencyExit": e.emergencyExit,
                    "playground": e.playground,
                    "outerElevator": e.outerElevator,
                    "dieselGenerator": e.dieselGenerator,
                    "doorman": e.doorman,
                    "outerSauna": e.outerSauna,
                    "pool": e.pool,
                    "seaView": e.seaView,
                    "mountainView": e.mountainView,
                    "streetView": e.streetView,
                    "bosporusView": e.bosporusView,
                },
                "neighborhood": {
                    "beach": e.beach,
                    "shoppingMall": e.shoppingMall,
                    "kindergarten": e.kindergarten,
                    "school": e.school,
                    "highSchool": e.highSchool,
                    "hospital": e.hospital,
                    "mosque": e.mosque,
                    "pharmacy": e.pharmacy,
                    "university": e.university,
                    "market": e.market,
                    "fireDepartment": e.fireDepartment,
                    "park": e.park,
                    "gym": e.gym,
                    "busStop": e.busStop,
                    "highway": e.highway,
                    "tram": e.tram,
                    "subway": e.subway,
                    "trainStation": e.trainStation,
                    "airport": e.airport,
                },
                "frontside": {
                    "north": e.north,
                    "east": e.east,
                    "south": e.south,
                    "west": e.west,
                }
            },
            images: images,
            location: { "latidude": lat, "longitude": lon },
            coordinates: new firebase.firestore.GeoPoint(lat, lon),
            g: {
                geohash: geofire.geohashForLocation([lat, lon]),
                geopoint: new firebase.firestore.GeoPoint(lat, lon),
            },
            createdAt: data ? data.createdAt : firebase.firestore.FieldValue.serverTimestamp(),
            checkedContacts: checkedContacts,
        }).then(() => {
            setOpenSnackbar(true)
        }).catch((error) => {
            console.log(`Unsuccessful returned error ${error}`)
        })
    }

    const handleDeletePic = (key) => {
        console.log(imagesToRemove)
        const targetImg = document.getElementById(`image-element-${key}`)
        if (targetImg !== null) {
            if (targetImg.classList.contains("remove-image-element")) {
/*                 targetImg.classList.remove("remove-image-element")
                const name = targetImg.getAttribute("name")
                setImagesToRemove(imagesToRemove.filter(item => item.url !== name)) */
                /* setImages(images => [...images, rememberImages[key]]) */
            } else {
                const name = targetImg.getAttribute("name")
                setImages(images.filter(item => item.url !== name))
/*                 targetImg.classList.add("remove-image-element")
                const name = targetImg.getAttribute("name")
                setImagesToRemove(imagesToRemove => [...imagesToRemove, rememberImages[key]]) */
                /* setImages(images.filter(item => item.url !== name)) */
            }
        }
    }

    /*     const onDrop = ({ removedIndex, addedIndex }) => {
            console.log({ removedIndex, addedIndex });
        };
     */
    const realEstateType = () => ([
        { id: 'house', title: 'House' },
        { id: 'apartment', title: 'Apartment' },
        { id: 'office', title: 'Office' },
        { id: 'buildingSite', title: 'Building Site' },
        { id: 'villa', title: 'Villa' },
    ])

    const marketingTypes = () => ([
        { id: 'rent', title: 'Rent' },
        { id: 'buy', title: 'Buy' },
    ])

    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('title' in fieldValues)
            temp.title = fieldValues.title ? "" : "This field is required."
        if ('subTitle' in fieldValues)
            temp.subTitle = fieldValues.subTitle ? "" : "This field is required."
        if ('body' in fieldValues)
            temp.body = fieldValues.body ? "" : "This field is required."
        if ('marketingTypeGroup' in fieldValues)
            temp.marketingTypeGroup = fieldValues.marketingTypeGroup.length !== 0 ? "" : "This field is required."
        if ('realEstateType' in fieldValues)
            temp.realEstateType = fieldValues.realEstateType.length !== 0 ? "" : "This field is required."
        if ('city' in fieldValues)
            temp.city = fieldValues.city ? "" : "This field is required."
        if ('province' in fieldValues)
            temp.province = fieldValues.province ? "" : "This field is required."
        setErrors({
            ...temp
        })

        if (fieldValues === values)
            return Object.values(temp).every(x => x === "")
    }

    const {
        values,
        errors,
        setErrors,
        handleInputChange,
        resetForm
    } = useForm(
        initialFValues,
        true,
        validate,
        setExpanded,
        resetUppy,
        setResetUppy,
        setCity,
        setProvince,
        setLon,
        setLat
    );

    const handleSubmit = e => {
        e.preventDefault()
        setImages(images.filter((el) => !imagesToRemove.includes(el)))
        if (checkedContacts.length < 2) {
            setOpenContactFailSnackbar(true)
        } else {
            if (validate()) {
                sendNewEstate(values)
                setResetUppy(!resetUppy)
                if (!data) {
                    resetForm()
                    setImages([])
                }
/*                 let removeElems = document.querySelectorAll(".remove-image-element"); */

/*                 [].forEach.call(removeElems, function (el) {
                    el.classList.remove("remove-image-element")
                }); */
            }
        }
    }

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false)
        setOpenContactSnackbar(false)
        setOpenUpdateContactSnackbar(false)
        setOpenContactFailSnackbar(false)
        setOpenDeleteContactSnackbar(false)
    }

    useEffect(() => {
        if (values.marketingTypeGroup === 'rent') {
            setPriceLabel('Price per month in TL')
        }
        if (values.marketingTypeGroup === 'buy') {
            setPriceLabel('Price in TL')
        }
        if (values.marketingTypeGroup === 'build') {
            setPriceLabel('Price in TL')
        }
        if (city !== '') {
            values.city = city
        }
        if (province !== '') {
            values.province = province
        }
    }, [values, city, province])

    return (
        <Box variant="div" sx={{
            bgcolor: 'background.default',
            color: 'text.primary',
        }}>
            {user &&
                <div className="main-container">
                    <div className="main-subcontainer">
                        <Box sx={{ p: 2 }}>
                            <Form onSubmit={handleSubmit}>
                                <Box sx={{ p: 1, display: 'flex', justifyContent: 'space-between' }}>
                                    {data &&
                                        <Typography variant="h2" mb={2}>
                                            Update your real estate
                                        </Typography>
                                    }
                                    {!data &&
                                        <Typography variant="h2" mb={2}>
                                            Add a real estate
                                        </Typography>
                                    }
                                    <Box sx={{ pl: 2 }}>
                                        <Controls.Switch
                                            name="active"
                                            label="Active"
                                            value={values.active}
                                            onChange={handleInputChange}
                                        />
                                    </Box>
                                </Box>
                                <Box sx={{ flexGrow: 1 }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <Controls.Input
                                                name="title"
                                                label="Title"
                                                value={values.title}
                                                onChange={handleInputChange}
                                                error={errors.title}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Controls.Input
                                                name="subTitle"
                                                label="Subtitle"
                                                value={values.subTitle}
                                                onChange={handleInputChange}
                                                error={errors.subTitle}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Controls.Textarea
                                                name="body"
                                                label="Body Text"
                                                value={values.body}
                                                onChange={handleInputChange}
                                                error={errors.body}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={3}>
                                            <Controls.Select
                                                name="marketingTypeGroup"
                                                label="Marketing Type"
                                                value={values.marketingTypeGroup}
                                                onChange={handleInputChange}
                                                options={marketingTypes()}
                                                error={errors.marketingTypeGroup}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={3}>
                                            <Controls.Select
                                                name="realEstateType"
                                                label="Real Estate Type"
                                                value={values.realEstateType}
                                                onChange={handleInputChange}
                                                options={realEstateType()}
                                                error={errors.realEstateType}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={3}>
                                            <Controls.Input
                                                name="price"
                                                label={priceLabel}
                                                type="number"
                                                value={values.price}
                                                onChange={handleInputChange}
                                                error={errors.price}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={3}>
                                            <Controls.Input
                                                name="monthlyFee"
                                                label="Monthly fee"
                                                type="number"
                                                value={values.monthlyFee}
                                                onChange={handleInputChange}
                                                error={errors.monthlyFee}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={3}>
                                            <Controls.Input
                                                name="squaremeterGross"
                                                label="Squaremeter gross"
                                                type="number"
                                                value={values.squaremeterGross}
                                                onChange={handleInputChange}
                                                error={errors.squaremeterGross}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={3}>
                                            <Controls.Input
                                                name="squaremeterNet"
                                                label="Squaremeter net"
                                                type="number"
                                                value={values.squaremeterNet}
                                                onChange={handleInputChange}
                                                error={errors.squaremeterNet}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={2}>
                                            <Controls.Input
                                                name="floor"
                                                label="Floor"
                                                type="number"
                                                value={values.floor}
                                                onChange={handleInputChange}
                                                error={errors.floor}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={2}>
                                            <Controls.Input
                                                name="rooms"
                                                label="Rooms"
                                                type="number"
                                                value={values.rooms}
                                                onChange={handleInputChange}
                                                error={errors.rooms}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={2}>
                                            <Controls.Input
                                                name="kitchens"
                                                label="Kitchens"
                                                type="number"
                                                value={values.kitchens}
                                                onChange={handleInputChange}
                                                error={errors.kitchens}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={2}>
                                            <Controls.Input
                                                name="bathrooms"
                                                label="Bathrooms"
                                                type="number"
                                                value={values.bathrooms}
                                                onChange={handleInputChange}
                                                error={errors.bathrooms}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={2}>
                                            <Controls.Input
                                                name="bedrooms"
                                                label="Bedrooms"
                                                type="number"
                                                value={values.bedrooms}
                                                onChange={handleInputChange}
                                                error={errors.bedrooms}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={2}>
                                            <Controls.Input
                                                name="parkingSpots"
                                                label="Parking Spots"
                                                type="number"
                                                value={values.parkingSpots}
                                                onChange={handleInputChange}
                                                error={errors.parkingSpots}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Accordion expanded={expanded === 'panel0'} onChange={handleAccordeonChange('panel0')}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    id="panel0a-header"
                                                >
                                                    <Typography>Contact</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    {allContacts &&
                                                        <Contact checkedContacts={checkedContacts} setCheckedContacts={setCheckedContacts} setShowUpdateContacts={setShowUpdateContacts} setContactData={setContactData} allContacts={allContacts} setShowContact={setShowContact} />
                                                    }
                                                    <Button onClick={() => { setShowContact(true); setContactData('') }} variant="outlined" size="small">
                                                        Add contact
                                                    </Button>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={6}>
                                                    <Accordion expanded={expanded === 'panel1'} onChange={handleAccordeonChange('panel1')}>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            id="panel1a-header"
                                                        >
                                                            <Typography>Inner Features</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <Controls.Switch
                                                                name="adsl"
                                                                label="ADSL"
                                                                value={values.adsl}
                                                                onChange={handleInputChange}
                                                            />
                                                            <Controls.Switch
                                                                name="wlan"
                                                                label="W-Lan"
                                                                value={values.wlan}
                                                                onChange={handleInputChange}
                                                            />
                                                            <Controls.Switch
                                                                name="fiberOpticInternet"
                                                                label="Fiber Optic Internet"
                                                                value={values.fiberOpticInternet}
                                                                onChange={handleInputChange}
                                                            />
                                                            <Controls.Switch
                                                                name="kitchen"
                                                                label="Kitchen"
                                                                value={values.kitchen}
                                                                onChange={handleInputChange}
                                                            />
                                                            <Controls.Switch
                                                                name="bath"
                                                                label="Bath"
                                                                value={values.bath}
                                                                onChange={handleInputChange}
                                                            />
                                                            <Controls.Switch
                                                                name="oven"
                                                                label="Oven"
                                                                value={values.oven}
                                                                onChange={handleInputChange}
                                                            />
                                                            <Controls.Switch
                                                                name="stove"
                                                                label="Stove"
                                                                value={values.stove}
                                                                onChange={handleInputChange}
                                                            />
                                                            <Controls.Switch
                                                                name="intercom"
                                                                label="Intercom"
                                                                value={values.intercom}
                                                                onChange={handleInputChange}
                                                            />
                                                            <Controls.Switch
                                                                name="basement"
                                                                label="Basement"
                                                                value={values.basement}
                                                                onChange={handleInputChange}
                                                            />
                                                            <Controls.Switch
                                                                name="gasConnection"
                                                                label="Gas Connection"
                                                                value={values.gasConnection}
                                                                onChange={handleInputChange}
                                                            />
                                                            <Controls.Switch
                                                                name="chimney"
                                                                label="Chimney"
                                                                value={values.chimney}
                                                                onChange={handleInputChange}
                                                            />
                                                            <Controls.Switch
                                                                name="washingMachine"
                                                                label="Washing Machine"
                                                                value={values.washingMachine}
                                                                onChange={handleInputChange}
                                                            />
                                                            <Controls.Switch
                                                                name="heating"
                                                                label="Heating"
                                                                value={values.heating}
                                                                onChange={handleInputChange}
                                                            />
                                                            <Controls.Switch
                                                                name="innerElevator"
                                                                label="Elevator"
                                                                value={values.innerElevator}
                                                                onChange={handleInputChange}
                                                            />
                                                            <Controls.Switch
                                                                name="disabledAccess"
                                                                label="Disabled Access"
                                                                value={values.disabledAccess}
                                                                onChange={handleInputChange}
                                                            />
                                                            <Controls.Switch
                                                                name="cableTelevision"
                                                                label="Cable Television"
                                                                value={values.cableTelevision}
                                                                onChange={handleInputChange}
                                                            />
                                                            <Controls.Switch
                                                                name="dishwasher"
                                                                label="Dishwasher"
                                                                value={values.dishwasher}
                                                                onChange={handleInputChange}
                                                            />
                                                            <Controls.Switch
                                                                name="airCondition"
                                                                label="Air Condition"
                                                                value={values.airCondition}
                                                                onChange={handleInputChange}
                                                            />
                                                            <Controls.Switch
                                                                name="furniture"
                                                                label="Furniture"
                                                                value={values.furniture}
                                                                onChange={handleInputChange}
                                                            />
                                                            <Controls.Switch
                                                                name="blinds"
                                                                label="Blinds"
                                                                value={values.blinds}
                                                                onChange={handleInputChange}
                                                            />
                                                            <Controls.Switch
                                                                name="steelDoor"
                                                                label="Steel Door"
                                                                value={values.steelDoor}
                                                                onChange={handleInputChange}
                                                            />
                                                            <Controls.Switch
                                                                name="terrace"
                                                                label="Terrace"
                                                                value={values.terrace}
                                                                onChange={handleInputChange}
                                                            />
                                                            <Controls.Switch
                                                                name="alarmSystem"
                                                                label="Alarm System"
                                                                value={values.alarmSystem}
                                                                onChange={handleInputChange}
                                                            />
                                                            <Controls.Switch
                                                                name="fireAlarm"
                                                                label="Fire Alarm"
                                                                value={values.fireAlarm}
                                                                onChange={handleInputChange}
                                                            />
                                                            <Controls.Switch
                                                                name="innerSauna"
                                                                label="Sauna"
                                                                value={values.innerSauna}
                                                                onChange={handleInputChange}
                                                            />
                                                            <Controls.Switch
                                                                name="balcony"
                                                                label="Balcony"
                                                                value={values.balcony}
                                                                onChange={handleInputChange}
                                                            />
                                                            <Controls.Switch
                                                                name="whirlpool"
                                                                label="Whirlpool"
                                                                value={values.whirlpool}
                                                                onChange={handleInputChange}
                                                            />
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Accordion expanded={expanded === 'panel2'} onChange={handleAccordeonChange('panel2')}>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            id="panel2a-header"
                                                        >
                                                            <Typography>Outer Features</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <Controls.Switch
                                                                name="hSecurityService"
                                                                label="24h Security Service"
                                                                value={values.hSecurityService}
                                                                onChange={handleInputChange}
                                                            />
                                                            <Controls.Switch
                                                                name="tennisCourt"
                                                                label="Tennis Court"
                                                                value={values.tennisCourt}
                                                                onChange={handleInputChange}
                                                            />
                                                            <Controls.Switch
                                                                name="footballField"
                                                                label="Football Field"
                                                                value={values.footballField}
                                                                onChange={handleInputChange}
                                                            />
                                                            <Controls.Switch
                                                                name="basketballCourt"
                                                                label="Basketball Court"
                                                                value={values.basketballCourt}
                                                                onChange={handleInputChange}
                                                            />
                                                            <Controls.Switch
                                                                name="cameraMonitoring"
                                                                label="Camera Monitoring"
                                                                value={values.cameraMonitoring}
                                                                onChange={handleInputChange}
                                                            />
                                                            <Controls.Switch
                                                                name="parkingSpotOpen"
                                                                label="Parking SpotOpen"
                                                                value={values.parkingSpotOpen}
                                                                onChange={handleInputChange}
                                                            />
                                                            <Controls.Switch
                                                                name="parkingSpotClosed"
                                                                label="Parking SpotClosed"
                                                                value={values.parkingSpotClosed}
                                                                onChange={handleInputChange}
                                                            />
                                                            <Controls.Switch
                                                                name="undergroundCarPark"
                                                                label="Underground Car Park"
                                                                value={values.undergroundCarPark}
                                                                onChange={handleInputChange}
                                                            />
                                                            <Controls.Switch
                                                                name="emergencyExit"
                                                                label="Emergency Exit"
                                                                value={values.emergencyExit}
                                                                onChange={handleInputChange}
                                                            />
                                                            <Controls.Switch
                                                                name="playground"
                                                                label="Playground"
                                                                value={values.playground}
                                                                onChange={handleInputChange}
                                                            />
                                                            <Controls.Switch
                                                                name="outerElevator"
                                                                label="Elevator"
                                                                value={values.outerElevator}
                                                                onChange={handleInputChange}
                                                            />
                                                            <Controls.Switch
                                                                name="dieselGenerator"
                                                                label="Diesel Generator"
                                                                value={values.dieselGenerator}
                                                                onChange={handleInputChange}
                                                            />
                                                            <Controls.Switch
                                                                name="doorman"
                                                                label="Doorman"
                                                                value={values.doorman}
                                                                onChange={handleInputChange}
                                                            />
                                                            <Controls.Switch
                                                                name="outerSauna"
                                                                label="Sauna"
                                                                value={values.outerSauna}
                                                                onChange={handleInputChange}
                                                            />
                                                            <Controls.Switch
                                                                name="pool"
                                                                label="Pool"
                                                                value={values.pool}
                                                                onChange={handleInputChange}
                                                            />
                                                            <Controls.Switch
                                                                name="seaView"
                                                                label="Sea View"
                                                                value={values.seaView}
                                                                onChange={handleInputChange}
                                                            />
                                                            <Controls.Switch
                                                                name="mountainView"
                                                                label="Mountain View"
                                                                value={values.mountainView}
                                                                onChange={handleInputChange}
                                                            />
                                                            <Controls.Switch
                                                                name="streetView"
                                                                label="Street View"
                                                                value={values.streetView}
                                                                onChange={handleInputChange}
                                                            />
                                                            <Controls.Switch
                                                                name="bosporusView"
                                                                label="Bosporus View"
                                                                value={values.bosporusView}
                                                                onChange={handleInputChange}
                                                            />
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Accordion expanded={expanded === 'panel3'} onChange={handleAccordeonChange('panel3')}>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            id="panel3a-header"
                                                        >
                                                            <Typography>Neighborhood</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <Controls.Switch
                                                                name="beach"
                                                                label="Beach"
                                                                value={values.beach}
                                                                onChange={handleInputChange}
                                                            />
                                                            <Controls.Switch
                                                                name="shoppingMall"
                                                                label="Shopping Mall"
                                                                value={values.shoppingMall}
                                                                onChange={handleInputChange}
                                                            />
                                                            <Controls.Switch
                                                                name="kindergarten"
                                                                label="Kindergarten"
                                                                value={values.kindergarten}
                                                                onChange={handleInputChange}
                                                            />
                                                            <Controls.Switch
                                                                name="school"
                                                                label="School"
                                                                value={values.school}
                                                                onChange={handleInputChange}
                                                            />
                                                            <Controls.Switch
                                                                name="highSchool"
                                                                label="High School"
                                                                value={values.highSchool}
                                                                onChange={handleInputChange}
                                                            />
                                                            <Controls.Switch
                                                                name="hospital"
                                                                label="Hospital"
                                                                value={values.hospital}
                                                                onChange={handleInputChange}
                                                            />
                                                            <Controls.Switch
                                                                name="mosque"
                                                                label="Mosque"
                                                                value={values.mosque}
                                                                onChange={handleInputChange}
                                                            />
                                                            <Controls.Switch
                                                                name="pharmacy"
                                                                label="Pharmacy"
                                                                value={values.pharmacy}
                                                                onChange={handleInputChange}
                                                            />
                                                            <Controls.Switch
                                                                name="university"
                                                                label="University"
                                                                value={values.university}
                                                                onChange={handleInputChange}
                                                            />
                                                            <Controls.Switch
                                                                name="market"
                                                                label="Market"
                                                                value={values.market}
                                                                onChange={handleInputChange}
                                                            />
                                                            <Controls.Switch
                                                                name="fireDepartment"
                                                                label="Fire Department"
                                                                value={values.fireDepartment}
                                                                onChange={handleInputChange}
                                                            />
                                                            <Controls.Switch
                                                                name="park"
                                                                label="Park"
                                                                value={values.park}
                                                                onChange={handleInputChange}
                                                            />
                                                            <Controls.Switch
                                                                name="gym"
                                                                label="Gym"
                                                                value={values.gym}
                                                                onChange={handleInputChange}
                                                            />
                                                            <Controls.Switch
                                                                name="busStop"
                                                                label="Bus Stop"
                                                                value={values.busStop}
                                                                onChange={handleInputChange}
                                                            />
                                                            <Controls.Switch
                                                                name="highway"
                                                                label="Highway"
                                                                value={values.highway}
                                                                onChange={handleInputChange}
                                                            />
                                                            <Controls.Switch
                                                                name="tram"
                                                                label="Tram"
                                                                value={values.tram}
                                                                onChange={handleInputChange}
                                                            />
                                                            <Controls.Switch
                                                                name="subway"
                                                                label="Subway"
                                                                value={values.subway}
                                                                onChange={handleInputChange}
                                                            />
                                                            <Controls.Switch
                                                                name="trainStation"
                                                                label="Train Station"
                                                                value={values.trainStation}
                                                                onChange={handleInputChange}
                                                            />
                                                            <Controls.Switch
                                                                name="airport"
                                                                label="Airport"
                                                                value={values.airport}
                                                                onChange={handleInputChange}
                                                            />
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Accordion expanded={expanded === 'panel4'} onChange={handleAccordeonChange('panel4')}>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            id="panel4a-header"
                                                        >
                                                            <Typography>Frontside</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <Controls.Switch
                                                                name="north"
                                                                label="North"
                                                                value={values.north}
                                                                onChange={handleInputChange}
                                                            />
                                                            <Controls.Switch
                                                                name="east"
                                                                label="East"
                                                                value={values.east}
                                                                onChange={handleInputChange}
                                                            />
                                                            <Controls.Switch
                                                                name="south"
                                                                label="South"
                                                                value={values.south}
                                                                onChange={handleInputChange}
                                                            />
                                                            <Controls.Switch
                                                                name="west"
                                                                label="West"
                                                                value={values.west}
                                                                onChange={handleInputChange}
                                                            />
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Map
                                                setLon={setLon}
                                                setLat={setLat}
                                                setCity={setCity}
                                                setProvince={setProvince}
                                                initLat={initLat}
                                                initLon={initLon}
                                                setInitLat={setInitLat}
                                                setInitLon={setInitLon}
                                                mapOptions={{
                                                    center: { lat: 39.445007, lng: 34.157856 },
                                                    zoom: 6,
                                                    mapTypeControl: false,
                                                    streetViewControl: false,
                                                    gestureHandling: checkGestureHandling(),
                                                    mapId: darkMode ? 'f54dca480796bb83' : '285e7d6c676ee9f'
                                                }}
                                                id="map-select" />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Controls.Input
                                                disabled={true}
                                                name="province"
                                                label="Province"
                                                value={province}
                                                onChange={handleInputChange}
                                                error={errors.province}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Controls.Input
                                                disabled={true}
                                                name="city"
                                                label="City"
                                                value={city}
                                                onChange={handleInputChange}
                                                error={errors.city}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={8}>
                                            <UppyField images={images} setImages={setImages} resetUppy={resetUppy} />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            {data &&
                                                <Grid container spacing={1}>
                                                    <DndContext
                                                        sensors={sensors}
                                                        collisionDetection={closestCenter}
                                                        onDragStart={handleDragStart}
                                                        onDragEnd={handleDragEnd}
                                                    >
                                                        <SortableContext
                                                            items={images}
                                                            strategy={rectSortingStrategy}
                                                        >
                                                            {images.map((id, i) =>
                                                                <Box className={`image-box`} key={i} sx={{ position: 'relative', mt: 2 }}>
                                                                    <IconButton
                                                                        aria-label="close"
                                                                        onClick={() => handleDeletePic(i)}
                                                                        sx={{
                                                                            position: 'absolute',
                                                                            right: 8,
                                                                            top: 8
                                                                        }}
                                                                    >
                                                                        <CloseIcon fontSize="small" />
                                                                    </IconButton>
                                                                    <SortableItem handleDeletePic={handleDeletePic} image={id} key={i} num={i} id={id} />
                                                                </Box>

                                                            )}
                                                        </SortableContext>
                                                    </DndContext>

                                                </Grid>
                                            }
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={3} sx={{ mb: 3 }}>
                                            <Stack
                                                direction="row"
                                                divider={<Divider orientation="vertical" flexItem />}
                                                spacing={2}
                                            >
                                                {data &&
                                                    <>
                                                        <Button variant="contained" type="submit" sx={{ color: '#ffffff' }}>Update</Button>
                                                    </>
                                                }
                                                {!data &&
                                                    <>

                                                        <Button variant="contained" type="submit" sx={{ color: '#ffffff' }}>Submit</Button>
                                                        <Button variant="contained" onClick={() => resetForm()} sx={{ color: '#ffffff' }}>Reset</Button>
                                                    </>
                                                }
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Form>
                        </Box>
                    </div>
                </div>
            }
            <SetContact setOpenContactSnackbar={setOpenContactSnackbar} user={user} showContact={showContact} setShowContact={setShowContact} />
            <UpdateContacts setOpenDeleteContactSnackbar={setOpenDeleteContactSnackbar} darkMode={darkMode} setOpenUpdateContactSnackbar={setOpenUpdateContactSnackbar} showUpdateContacts={showUpdateContacts} user={user} setShowUpdateContacts={setShowUpdateContacts} contactData={contactData} />
            <Snackbar open={openDeleteContactSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="error">
                    Contact deleted!
                </Alert>
            </Snackbar>
            <Snackbar open={openContactFailSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="error">
                    Please add a contact first!
                </Alert>
            </Snackbar>
            <Snackbar open={openContactSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="success">
                    The contact was submitted to the server!
                </Alert>
            </Snackbar>
            <Snackbar open={openUpdateContactSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="success">
                    The contact was updated!
                </Alert>
            </Snackbar>
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="success">
                    The real estate was submitted to the server!
                </Alert>
            </Snackbar>
        </Box>
    );
    function handleDragStart(event) {
        const { active } = event;
    }

    function handleDragEnd(event) {
        const { active, over } = event;

        if (active.id !== over.id) {
            setImages((images) => {
/*                 console.log(images.indexOf(active.id))
                console.log(images.indexOf(over.id)) */
                const oldIndex = images.indexOf(active.id);
                const newIndex = images.indexOf(over.id);


                return arrayMove(images, oldIndex, newIndex);
            });
        }
    }
}

export default AddRealEstate;