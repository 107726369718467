import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'
import { useState } from 'react'
import RealEstateDetailsGalleryModal from './RealEstateDetailsGalleryModal'

export default function DetailGallery({ data }) {

    const [showGallery, setShowGallery] = useState(false)
    const [initialSlide, setInitalSlide] = useState("0")

    if (data.length < 4) {
        return
    }

    const imageData = () => {
        if (data.length >= 4 && data.length < 8) {
            data[0].rows = 2
            data[0].cols = 2
            data[3].cols = 2
            let slicedData = data.slice(0, 4)
            return slicedData
        }
        if (data.length >= 8) {
            data[0].rows = 2
            data[0].cols = 2
            data[3].cols = 2
            data[4].cols = 2
            data[5].rows = 2
            data[5].cols = 2
            let slicedData = data.slice(0, 8)
            return slicedData
        }
    }


    return (
        <ImageList

            variant="quilted"
            cols={4}
            gap={8}
            rowHeight={121}
            className={data.length >= 4 && data.length < 8 ? 'four-detail-images' : 'eight-detail-images'}
        >
            {imageData().map((item, index) => (
                <ImageListItem key={item.url} cols={item.cols || 1} rows={item.rows || 1}>

                    <img
                        onClick={() => { setShowGallery(true); setInitalSlide(index); }}
                        src={item.url}
                        alt="Gallery"
                        loading="lazy"
                    />

                </ImageListItem>
            ))}
            <RealEstateDetailsGalleryModal showGallery={showGallery} setShowGallery={setShowGallery} galleryImages={data} initialSlide={initialSlide} />
        </ImageList>
    );
}


/* const itemData = [
    data.length === 4 && data[0].rows = 2
  {
    img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
    title: 'Breakfast',
    rows: 2,
    cols: 2,
  },
  {
    img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
    title: 'Burger',
  },
  {
    img: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
    title: 'Camera',
  },
  {
    img: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
    title: 'Coffee',
    cols: 2,
  },
  {
    img: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
    title: 'Hats',
    cols: 2,
  },
  {
    img: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
    title: 'Honey',
    author: '@arwinneil',
    rows: 2,
    cols: 2,
  },
  {
    img: 'https://images.unsplash.com/photo-1516802273409-68526ee1bdd6',
    title: 'Basketball',
  },
  {
    img: 'https://images.unsplash.com/photo-1518756131217-31eb79b20e8f',
    title: 'Fern',
  },
]; */