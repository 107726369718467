import { createGlobalState } from "react-hooks-global-state"

const {setGlobalState, useGlobalState} = createGlobalState({
    defaultCurrency: 'TRY',
    darkMode: true,
    language: 'en',
    mapsAPIkey: 'AIzaSyDZ9_dN1xY_CfDzldbNR-0a31vMZ1yU0Co',
    filterMarketingGroup: [],
    filterRealEstateType: []
})

export {useGlobalState, setGlobalState}