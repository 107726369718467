import { useDocumentData } from 'react-firebase-hooks/firestore'
import { doc, setDoc } from 'firebase/firestore'
import { firestore } from './firebase'

const CheckIfUserExistInDB = ({ user }) => {


  /*   GetUser(user.uid).then(data => isAdmin = data.data.roles) */

const ref = doc(firestore, `/users/${user.uid}`)

const [data/* , loading, error, snapshot */] = useDocumentData(ref)

/*     let userDbData = firestore.doc(`/users/${user.uid}`)

    const [data, loading, error, snapshot] = useDocumentData(userDbData); */

    const addNewUserToDB = async (e) => {
        await setDoc(doc(firestore, 'users', user.uid), {
            email: user.email,
            id: user.uid,
            roles: {
                "admin": false,
                "author": false,
                "reader": true
            }
          });
    }

    if (data === undefined) {
        addNewUserToDB();
    }

/*     console.log(data) */



    return (
        <></>
    );
}

export default CheckIfUserExistInDB;