const estateTypeName = (name) => {
    switch (name) {
        case 'apartment':
            return 'Apartment'
        case 'house':
            return 'House'
        case 'buildingSite':
            return 'Building Site'
        case 'office':
            return 'Office'
        case 'villa':
            return 'Villa'
        default:
            return ''
    }
}

export default estateTypeName;