
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import { Typography } from '@mui/material'
import MenuList from '@mui/material/MenuList'
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'

const Footer = () => {
    return (

        <Box>
            <Divider light />
            <Container maxWidth="auto">
                <Grid container spacing={5} sx={{pt:3}}>
                    <Grid item xs={12} sm={4}>
                        <Typography sx={{pl:2}} variant="body2" color="text.secondary">
                            Support
                        </Typography>

                        <MenuList>
                            <MenuItem>
                                <ListItemText>Here a link</ListItemText>
                            </MenuItem>
                            <MenuItem>
                                <ListItemText>Here another link</ListItemText>
                            </MenuItem>
                        </MenuList>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography sx={{pl:2}} variant="body2" color="text.secondary">
                            Company
                        </Typography>

                        <MenuList>
                            <MenuItem>
                                <ListItemText>Here a link</ListItemText>
                            </MenuItem>
                            <MenuItem>
                                <ListItemText>Here another link</ListItemText>
                            </MenuItem>
                        </MenuList>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography sx={{pl:2}} variant="body2" color="text.secondary">
                            About
                        </Typography>

                        <MenuList>
                            <MenuItem>
                                <ListItemText>Here a link</ListItemText>
                            </MenuItem>
                            <MenuItem>
                                <ListItemText>Here another link</ListItemText>
                            </MenuItem>
                        </MenuList>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}

export default Footer;