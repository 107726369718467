import ShoppingBag from '@mui/icons-material/ShoppingBag'
import ChildCare from '@mui/icons-material/ChildCare'
import School from '@mui/icons-material/School'
import Medication from '@mui/icons-material/Medication'
import MenuBook from '@mui/icons-material/MenuBook'
import LocalHospital from '@mui/icons-material/LocalHospital'
import FireExtinguisher from '@mui/icons-material/FireExtinguisher'
import Storefront from '@mui/icons-material/Storefront'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import WestIcon from '@mui/icons-material/West'
import EastIcon from '@mui/icons-material/East'
import NorthIcon from '@mui/icons-material/North'
import SouthIcon from '@mui/icons-material/South'
import NetworkCellIcon from '@mui/icons-material/NetworkCell'
import CellWifiIcon from '@mui/icons-material/CellWifi'
import KitchenIcon from '@mui/icons-material/Kitchen'
import NetworkCheckIcon from '@mui/icons-material/NetworkCheck';
import ShowerIcon from '@mui/icons-material/Shower';
import MicrowaveIcon from '@mui/icons-material/Microwave';
import CountertopsIcon from '@mui/icons-material/Countertops';
import AodIcon from '@mui/icons-material/Aod';
import FlipToBackIcon from '@mui/icons-material/FlipToBack';
import BeachAccess from '@mui/icons-material/BeachAccess'
import OpacityIcon from '@mui/icons-material/Opacity';
import FireplaceIcon from '@mui/icons-material/Fireplace';
import LocalLaundryServiceIcon from '@mui/icons-material/LocalLaundryService';
import HeatPumpIcon from '@mui/icons-material/HeatPump';
import ElevatorIcon from '@mui/icons-material/Elevator';
import AccessibleIcon from '@mui/icons-material/Accessible';
import TvIcon from '@mui/icons-material/Tv';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import ChairIcon from '@mui/icons-material/Chair';
import BlindsClosedIcon from '@mui/icons-material/BlindsClosed';
import DoorFrontIcon from '@mui/icons-material/DoorFront';
import BalconyIcon from '@mui/icons-material/Balcony';
import DeckIcon from '@mui/icons-material/Deck';
import WavesIcon from '@mui/icons-material/Waves';
import FireExtinguisherIcon from '@mui/icons-material/FireExtinguisher';
import LockIcon from '@mui/icons-material/Lock';
import PoolIcon from '@mui/icons-material/Pool';
import HotTubIcon from '@mui/icons-material/HotTub';
import SecurityIcon from '@mui/icons-material/Security';
import SportsTennisIcon from '@mui/icons-material/SportsTennis';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import SportsBasketballIcon from '@mui/icons-material/SportsBasketball';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import CarCrashIcon from '@mui/icons-material/CarCrash';
import CarRentalIcon from '@mui/icons-material/CarRental';
import FollowTheSignsIcon from '@mui/icons-material/FollowTheSigns';
import ChildCareIcon from '@mui/icons-material/ChildCare';
import ElectricMeterIcon from '@mui/icons-material/ElectricMeter';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import WaterIcon from '@mui/icons-material/Water';
import LandscapeIcon from '@mui/icons-material/Landscape';
import AddRoadIcon from '@mui/icons-material/AddRoad';
import StreetviewIcon from '@mui/icons-material/Streetview';
import MosqueIcon from '@mui/icons-material/Mosque';
import ParkIcon from '@mui/icons-material/Park';
import SportsGymnasticsIcon from '@mui/icons-material/SportsGymnastics';
import DepartureBoardIcon from '@mui/icons-material/DepartureBoard';
import SpeedIcon from '@mui/icons-material/Speed';
import TramIcon from '@mui/icons-material/Tram';
import SubwayIcon from '@mui/icons-material/Subway';
import TrainIcon from '@mui/icons-material/Train';
import LocalAirportIcon from '@mui/icons-material/LocalAirport';

const DetailIcons = (name) => {
    switch (name) {
        case 'adsl':
            return <NetworkCellIcon />
        case 'wlan':
            return <CellWifiIcon />
        case 'fiberOpticInternet':
            return <NetworkCheckIcon />
        case 'kitchen':
            return <KitchenIcon />
        case 'bath':
            return <ShowerIcon />
        case 'oven':
            return <MicrowaveIcon />
        case 'stove':
            return <CountertopsIcon />
        case 'intercom':
            return <AodIcon />
        case 'basement':
            return <FlipToBackIcon />
        case 'gasConnection':
            return <OpacityIcon />
        case 'chimney':
            return <FireplaceIcon />
        case 'washingMachine':
            return <LocalLaundryServiceIcon />
        case 'heating':
            return <HeatPumpIcon />
        case 'innerElevator':
            return <ElevatorIcon />
        case 'disabledAccess':
            return <AccessibleIcon />
        case 'cableTelevision':
            return <TvIcon />
        case 'dishwasher':
            return <RestaurantIcon />
        case 'airCondition':
            return <AcUnitIcon />
        case 'furniture':
            return <ChairIcon />
        case 'blinds':
            return <BlindsClosedIcon />
        case 'steelDoor':
            return <DoorFrontIcon />
        case 'terrace':
            return <DeckIcon />
        case 'alarmSystem':
            return <LockIcon />
        case 'fireAlarm':
            return <FireExtinguisherIcon />
        case 'innerSauna':
            return <WavesIcon />
        case 'balcony':
            return <BalconyIcon />
        case 'whirlpool':
            return <HotTubIcon />
        case 'hSecurityService':
            return <SecurityIcon />
        case 'tennisCourt':
            return <SportsTennisIcon />
        case 'footballField':
            return <SportsSoccerIcon />
        case 'basketballCourt':
            return <SportsBasketballIcon />
        case 'cameraMonitoring':
            return <VideoCameraFrontIcon />
        case 'parkingSpotOpen':
            return <LocalParkingIcon />
        case 'parkingSpotClosed':
            return <CarCrashIcon />
        case 'undergroundCarPark':
            return <CarRentalIcon />
        case 'emergencyExit':
            return <FollowTheSignsIcon />
        case 'playground':
            return <ChildCareIcon />
        case 'outerElevator':
            return <ElevatorIcon />
        case 'dieselGenerator':
            return <ElectricMeterIcon />
        case 'doorman':
            return <LocalPoliceIcon />
        case 'outerSauna':
            return <WavesIcon />
        case 'pool':
            return <PoolIcon />
        case 'seaView':
            return <WaterIcon />
        case 'mountainView':
            return <LandscapeIcon />
        case 'streetView':
            return <AddRoadIcon />
        case 'bosporusView':
            return <StreetviewIcon />
        case 'beach':
            return <BeachAccess />
        case 'shoppingMall':
            return <ShoppingBag />
        case 'kindergarten':
            return <ChildCare />
        case 'school':
            return <MenuBook />
        case 'highSchool':
            return <LocalHospital />
        case 'hospital':
            return <AccountBalanceIcon />
        case 'mosque':
            return <MosqueIcon />
        case 'pharmacy':
            return <Medication />
        case 'university':
            return <School />
        case 'market':
            return <Storefront />
        case 'fireDepartment':
            return <FireExtinguisher />
        case 'park':
            return <ParkIcon />
        case 'gym':
            return <SportsGymnasticsIcon />
        case 'busStop':
            return <DepartureBoardIcon />
        case 'highway':
            return <SpeedIcon />
        case 'tram':
            return <TramIcon />
        case 'subway':
            return <SubwayIcon />
        case 'trainStation':
            return <TrainIcon />
        case 'airport':
            return <LocalAirportIcon />
        case 'north':
            return <NorthIcon />
        case 'east':
            return <EastIcon />
        case 'south':
            return <SouthIcon />
        case 'west':
            return <WestIcon />
        default:
            return <SpeedIcon />
    }
}

export default DetailIcons;