import DetailFeaturesObject from './DetailFeaturesObject'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

const DetailFeatures = ({ featureObject }) => {

    const { t } = useTranslation();

    const areTruthy = (featureData) => {
        const trueCheck = Object.values(featureData).sort().reduce((a, item) => a + item, 0)
        if (trueCheck > 0) {
            return true
        } else {
            return false
        }
    }

    const getTitle = (title) => {
        switch (title) {
            case 'frontside':
                return 'Frontside'
            case 'neighborhood':
                return 'Neighborhood Features'
            case 'innerFeatures':
                return 'Inner Features'
            case 'outerFeatures':
                return 'Outer Features'
            default:
                return 'Features'
        }
    }

    return (
        <>
            {Object.keys(featureObject).sort().map((data, i, row) => (
                <Box variant="div" sx={{ pt: 3 }} key={data}>
                    {areTruthy(featureObject[data]) &&
                        <>
                            <Typography variant="h3">{t(getTitle(data).toLowerCase().replace(/ /g,'_'))}</Typography>
                            <DetailFeaturesObject getTitle={getTitle} data={data} featureObject={featureObject} detailFeaturesObject={featureObject[data]} />
                            {i + 1 !== row.length &&
                                <Divider light />
                            }
                        </>
                    }
                </Box>
            ))}
        </>
    );
}

export default DetailFeatures;