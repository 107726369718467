const estateFeatureName = (name) => {
    switch (name) {
        case 'adsl':
            return 'ADSL'
        case 'wlan':
            return 'W-Lan'
        case 'fiberOpticInternet':
            return 'Fiber Optic Internet'
        case 'kitchen':
            return 'Kitchen'
        case 'bath':
            return 'Bath'
        case 'oven':
            return 'Oven'
        case 'stove':
            return 'Stove'
        case 'intercom':
            return 'Intercom'
        case 'basement':
            return 'Basement'
        case 'gasConnection':
            return 'Gas Connection'
        case 'chimney':
            return 'Chimney'
        case 'washingMachine':
            return 'Washing Machine'
        case 'heating':
            return 'Heating'
        case 'innerElevator':
            return 'Elevator'
        case 'disabledAccess':
            return 'Disabled Access'
        case 'cableTelevision':
            return 'Cable Television'
        case 'dishwasher':
            return 'Dishwasher'
        case 'airCondition':
            return 'Air Condition'
        case 'furniture':
            return 'Furniture'
        case 'blinds':
            return 'Blinds'
        case 'steelDoor':
            return 'Steel Door'
        case 'terrace':
            return 'Terrace'
        case 'alarmSystem':
            return 'Alarm System'
        case 'fireAlarm':
            return 'Fire Alarm'
        case 'innerSauna':
            return 'Sauna'
        case 'balcony':
            return 'Balcony'
        case 'whirlpool':
            return 'Whirlpool'
        case 'hSecurityService':
            return '24h Security Service'
        case 'tennisCourt':
            return 'Tennis Court'
        case 'footballField':
            return 'Football Field'
        case 'basketballCourt':
            return 'Basketball Court'
        case 'cameraMonitoring':
            return 'Camera Monitoring'
        case 'parkingSpotOpen':
            return 'Parking SpotOpen'
        case 'parkingSpotClosed':
            return 'Parking Spot Closed'
        case 'undergroundCarPark':
            return 'Underground Car Park'
        case 'emergencyExit':
            return 'Emergency Exit'
        case 'playground':
            return 'Playground'
        case 'outerElevator':
            return 'Elevator'
        case 'dieselGenerator':
            return 'Diesel Generator'
        case 'doorman':
            return 'Doorman'
        case 'outerSauna':
            return 'Sauna'
        case 'pool':
            return 'Pool'
        case 'seaView':
            return 'Sea View'
        case 'mountainView':
            return 'Mountain View'
        case 'streetView':
            return 'Street View'
        case 'bosporusView':
            return 'Bosporus View'
        case 'beach':
            return 'Beach'
        case 'shoppingMall':
            return 'Shopping Mall'
        case 'kindergarten':
            return 'Kindergarten'
        case 'school':
            return 'School'
        case 'highSchool':
            return 'High School'
        case 'hospital':
            return 'Hospital'
        case 'mosque':
            return 'Mosque'
        case 'pharmacy':
            return 'Pharmacy'
        case 'university':
            return 'University'
        case 'market':
            return 'Market'
        case 'fireDepartment':
            return 'Fire Department'
        case 'park':
            return 'Park'
        case 'gym':
            return 'Gym'
        case 'busStop':
            return 'Bus Stop'
        case 'highway':
            return 'Highway'
        case 'tram':
            return 'Tram'
        case 'subway':
            return 'Subway'
        case 'trainStation':
            return 'Train Station'
        case 'airport':
            return 'Airport'
        case 'north':
            return 'North'
        case 'east':
            return 'East'
        case 'south':
            return 'South'
        case 'west':
            return 'West'
        default:
            return ''
    }
}

export default estateFeatureName;