import { useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { firestore } from '../firebase'
import { doc } from 'firebase/firestore'
import { useDocument } from 'react-firebase-hooks/firestore'
import Tooltip from '@mui/material/Tooltip'
import Avatar from '@mui/material/Avatar'
import PhoneIcon from '@mui/icons-material/Phone'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import EmailIcon from '@mui/icons-material/Email'
import HomeIcon from '@mui/icons-material/Home'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import RealEstateDetailMap from '../RealEstateDetails/RealEstateDetailMap'
import { useGlobalState } from '../GlobalState'
import { useTranslation } from 'react-i18next'

const ContactModalData = ({ contact }) => {

    const { t } = useTranslation();

    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
    const [showAddress, setShowAddress] = useState(false)
    const ref = doc(firestore, 'contacts', contact.id)
    const [darkMode] = useGlobalState('darkMode')

    const [thisContact] = useDocument(ref)

    const handleClose = () => {
        setShowAddress(false)
    }

    return (
        <>
            {thisContact &&
                <Box sx={{ p: 2, display: 'flex', flexWrap: 'wrap', alignContent: 'flex-start' }}>
                    {JSON.stringify(thisContact.data().image.length) > 0 &&
                        <Box sx={{ flexShrink: '0', pr: 2, pb: 2 }}>
                            <Avatar
                                alt={thisContact.data().name}
                                src={thisContact.data().image[0].url}
                                sx={{ width: 100, height: 100 }}
                            />
                        </Box>
                    }
                    <Box sx={{ flexGrow: '1', flexShrink: '1' }}>
                        <Typography sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }} variant="h6">
                            {thisContact.data().name}
                        </Typography>
                        <Stack
                            direction="row"
                            divider={<Divider orientation="vertical" flexItem />}
                            spacing={1}
                            sx={{ pl: 2 }}
                        >
                            {thisContact.data().address &&
                                <Tooltip title={t('show_address')}>
                                    < IconButton
                                        onClick={() => setShowAddress(true)}
                                        edge="start"
                                        color="inherit"
                                        aria-label="address"
                                    >
                                        <HomeIcon />
                                    </IconButton>
                                </Tooltip>
                            }
                            {thisContact.data().email &&
                                <Tooltip title={t('send_email')}>
                                    < IconButton
                                        edge="start"
                                        color="inherit"
                                        aria-label="email"
                                        target="_blank"
                                        href={`mailto:${thisContact.data().email}`}
                                    >
                                        <EmailIcon />
                                    </IconButton>
                                </Tooltip>
                            }
                            {thisContact.data().phone &&
                                <Tooltip title={t('call_number')}>
                                    < IconButton
                                        edge="start"
                                        color="inherit"
                                        aria-label="phone"
                                        target="_blank"
                                        href={`tel:${thisContact.data().phone}`}
                                    >
                                        <PhoneIcon />
                                    </IconButton>
                                </Tooltip>
                            }
                            {thisContact.data().whatsapp &&
                                <Tooltip title={t('send_whatsapp')}>
                                    <IconButton
                                        edge="start"
                                        color="inherit"
                                        aria-label="whatsapp"
                                        target="_blank"
                                        href={`https://wa.me/${thisContact.data().whatsapp}`}
                                    >
                                        <WhatsAppIcon />
                                    </IconButton>
                                </Tooltip>
                            }
                        </Stack>
                    </Box>
                    <Dialog
                        fullScreen={fullScreen}
                        maxWidth='lg'
                        fullWidth={true}
                        open={showAddress}
                        onClose={handleClose}
                    >
                        <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between', borderBottom: 1, borderColor: 'divider' }}>
                            <Typography variant="h2">
                                {t('address')}
                            </Typography>
                            <Box>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={handleClose}
                                    aria-label="close"
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        </Box>
                        <DialogContent>
                            <Typography sx={{mb: 2}} variant="h3">
                                {thisContact.data().address}
                            </Typography>

                            {thisContact.data().location &&
                                <RealEstateDetailMap
                                    mapOptions={{
                                        center: {
                                            lat: thisContact.data().location.latidude,
                                            lng: thisContact.data().location.longitude
                                        },
                                        zoom: 12,
                                        mapTypeControl: false,
                                        gestureHandling: 'cooperative',
                                        mapId: darkMode ? 'f54dca480796bb83' : '285e7d6c676ee9f'
                                    }}
                                    data={thisContact.data().location}
                                    id="turkishRealEstateDetailContactMap"
                                />
                            }
                        </DialogContent>
                    </Dialog>
                </Box>
            }
        </>
    );
}

export default ContactModalData;