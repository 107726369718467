import {useState, useEffect} from "react";
import homeMarker from '../../home-marker.png'

const RealEstateDetailMap = ({mapOptions, id, data}) => {

    const [options] = useState(mapOptions);

    const OnMainMapLoad = (options, id) => {
        const map = new window.google.maps.Map(
            document.getElementById(id),
            options
        );
        useData(map);
    }

    const useData = (map) => {
        new window.google.maps.Marker({
            position: new window.google.maps.LatLng(data.latidude, data.longitude),
            map: map,
            icon: homeMarker
        })
    }

    useEffect(() => {
        if (!window.google) {
            const s = document.createElement('script');
            s.type = 'text/javascript';
            s.src = `https://maps.google.com/maps/api/js?key=AIzaSyDZ9_dN1xY_CfDzldbNR-0a31vMZ1yU0Co`;
            const x = document.getElementsByTagName('script')[0];
            x.parentNode.insertBefore(s, x);
            s.addEventListener('load', () => {
                OnMainMapLoad(options, id);
            });
        } else {
            OnMainMapLoad(options, id);
        }
      }, [])

    return (
        <div id={id} />
    );
}

export default RealEstateDetailMap;