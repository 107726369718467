import { forwardRef, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import ContactModalData from './ContactModalData'
import Divider from '@mui/material/Divider'
import Slide from '@mui/material/Slide'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ContactModal = ({ contactData, showContactModal, setShowContactModal }) => {

    const { t } = useTranslation();

    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

    const handleClose = () => {
        setShowContactModal(false);
    }

    return (
        <Dialog
            fullScreen={fullScreen}
            maxWidth='lg'
            fullWidth={true}
            open={showContactModal}
            onClose={handleClose}
            TransitionComponent={Transition}

        >
            <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between', borderBottom: 1, borderColor: 'divider' }}>
                <Typography variant="h2">
                {t('contact')}
                </Typography>
                <Box>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Box>
            <DialogContent>
                <Grid container spacing={2} divider={<Divider orientation="vertical" flexItem />}>
                    {contactData.slice(1).map((contact) => (
                        <Grid key={contact.id} item xs={12} sm={6} lg={4}>
                            <motion.div
                                initial={{ rotate: 180, scale: 0 }}
                                animate={{ rotate: 0, scale: 1 }}
                                transition={{
                                    type: "spring",
                                    stiffness: 260,
                                    damping: 20
                                }}
                            >
                                <ContactModalData contact={contact} />
                            </motion.div>

                        </Grid>
                    ))}
                </Grid>
            </DialogContent>
        </Dialog >
    );
}

export default ContactModal;