import * as React from 'react';
import { createCustomEqual } from 'fast-equals'
import createHTMLMapMarker from './html-map-marker.js'
import { doc } from 'firebase/firestore'
import { firestore } from './firebase'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import numberWithDot from './numberWithDot'
import { useGlobalState } from './GlobalState'
import { Link as RouterLink } from 'react-router-dom'
import Link from '@mui/material/Link'
import Box from '@mui/material/Box'

const Map = ({ filterRealEstateType, mapId, onIdle, children, style, posts, setAnchorEl, markersArray, setMarkersArray, setPopoverContent, ...options }) => {

    const [defaultCurrency] = useGlobalState('defaultCurrency')
    const [darkMode] = useGlobalState('darkMode')

    const handleClick = (el) => {
        setAnchorEl(el)
    };

    const docRef = doc(firestore, 'currency', 'exchangeValue')

    const [dataCurrency/* , loading, error, snapshot */] = useDocumentData(docRef)

    const calculatePrice = (price, currency) => {
        if (currency === 'TRY') {
            return Number(price) * 1
        } else {
            return Number(price) * Number(dataCurrency.resultFromApi.rates[currency])
        }
    }

    const currencySymbol = () => {
        switch (defaultCurrency) {
            case 'EUR':
                return ' €'
            case 'USD':
                return ' $'
            default:
                return ' ₺'
        }
    }

    const ref = React.useRef(null);
    const [map, setMap] = React.useState();

    React.useEffect(() => {
        if (ref.current && !map) {
            setMap(new window.google.maps.Map(ref.current, { mapId: mapId }));
        }
    }, [ref, map, mapId]);

    useDeepCompareEffectForMaps(() => {
        if (map) {
            map.setOptions(options);
        }
    }, [map, options]);

    React.useEffect(() => {
        if (map) {


            if (posts.length > 0) {

                if (markersArray.length > 0) {
                    markersArray.map((marker, i) => {
                        if (posts.some(e => e.id === marker.id)) {
                        } else {
                            const elements = document.querySelectorAll(`.container-${marker.id}`)
                            for (let elem of elements) {
                                elem.remove()
                            }

                            markersArray.splice(markersArray.findIndex(v => v.id === marker.id), 1);
                        }
                    })
                }

                posts.map((post) => {


/*                     var element = document.getElementById(post.data().id);
                    if (element) {
                        element.remove()
                    } */
                

                    const marker = createHTMLMapMarker({
                        latlng: { lat: post.data().location.latidude, lng: post.data().location.longitude },
                        map: map,
                        id: post.data().id,
                        html: `<div class="container-${post.data().id}"><div id="bubble-${post.data().id}" class="mapmarker ${darkMode ? "dark-marker" : "light-marker"}">${dataCurrency && numberWithDot(Number(calculatePrice(post.data().price, defaultCurrency)).toFixed(0)) + currencySymbol()}</div></div>`
                    });

                    var index = markersArray.findIndex(m => m.id === marker.id)
                    index === -1 && setMarkersArray(markersArray => [...markersArray, marker])

                    marker.addListener("click", (event) => {
                        handleClick(marker.div)
                        setPopoverContent(
                            <Link component={RouterLink} to={`/real-estate/${post.data().id}-${post.data().humanReadableUrl}`}>
                                {post.data().images.length < 1 ?
                                    <img src="https://firebasestorage.googleapis.com/v0/b/turkishimmo-fd59c.appspot.com/o/template.jpg?alt=media&token=ba7c0355-0d27-4f8a-934e-92d87102343d" />
                                    :
                                    <img src={post.data().images[0].url} alt="Map Estate" />
                                }
                                <Box sx={{ padding: '16px' }}>
                                    <h3>{post.data().title}</h3>
                                </Box>
                            </Link>
                        )
                    });









                })
            }


            ['idle'].forEach((eventName) =>
                window.google.maps.event.clearListeners(map, eventName)
            )

            if (onIdle) {
                map.addListener('idle', () => onIdle(map));
            }
        }
    }, [map, onIdle, defaultCurrency, filterRealEstateType]);

    return (
        <>
            <div ref={ref} style={style} />
            {React.Children.map(children, (child) => {
                if (React.isValidElement(child)) {
                    // set the map prop on the child component
                    return React.cloneElement(child, { map });
                }
            })}

        </>
    );
}

const deepCompareEqualsForMaps = createCustomEqual(
    (deepEqual) => (a, b) => {
        /*         if (
                    isLatLngLiteral(a) ||
                    a instanceof window.google.maps.LatLng ||
                    isLatLngLiteral(b) ||
                    b instanceof window.google.maps.LatLng
                ) {
                    return new window.google.maps.LatLng(a).equals(new window.google.maps.LatLng(b));
                } */

        // TODO extend to other types

        // use fast-equals for other objects
        return deepEqual(a, b);
    }
);

function useDeepCompareMemoize(value) {
    const ref = React.useRef();

    if (!deepCompareEqualsForMaps(value, ref.current)) {
        ref.current = value;
    }

    return ref.current;
}

function useDeepCompareEffectForMaps(
    callback,
    dependencies
) {
    React.useEffect(callback, dependencies.map(useDeepCompareMemoize));
}



export default Map;