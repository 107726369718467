import { firestore } from '../../firebase'
import { doc } from 'firebase/firestore'
import { useParams, Navigate } from "react-router-dom"
import { useDocumentData } from 'react-firebase-hooks/firestore'
import AddRealEstate from '../AddRealEstate/AddRealEstate'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../../firebaseAuth'

const UpdateRealEstate = ({user}) => {
    const { id } = useParams()
    const ref = doc(firestore, `/realEstates/${id}`)
    const [data/* , loading, error, snapshot */] = useDocumentData(ref)

    return (
        <>
            {data &&
                <>
                    {user &&
                        <>
                            {user.uid === data.user ?
                                <AddRealEstate user={user} data={data} id={id} />
                                :
                                <Navigate replace to="/" />
                            }
                        </>
                    }
                </>
            }
        </>
    );
}

export default UpdateRealEstate;