import { useState } from "react"
import Box from "@mui/material/Box"
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import estateFeatureName from './estateFeatureName'
import DetailIcons from './DetailIcons'
import Button from '@mui/material/Button'
import { useTranslation } from 'react-i18next'

const DetailFeaturesObject = ({ detailFeaturesObject, featureObject, getTitle, data }) => {

    const { t } = useTranslation();

    const [expanded, setExpanded] = useState(false)
    const dataForDisplay = expanded ? 500 : 6

    var result = Object.keys(detailFeaturesObject).filter(function (x) {
        return detailFeaturesObject[x] !== false;
    })

    return (
        <>
            <Box
                sx={{
                    p: 2,
                    bgcolor: 'background.default',
                    display: 'grid',
                    gridTemplateColumns: { xs: '1fr', md: '1fr 1fr', lg: '1fr 1fr 1fr' },
                    gap: 2,
                }}
            >
                {result.sort().slice(0, dataForDisplay).map((detailData) => (
                    featureObject[data][detailData] &&
                    <ListItem key={detailData}>
                        <ListItemIcon>
                            {DetailIcons(detailData)}
                        </ListItemIcon>
                        <ListItemText primary={t(estateFeatureName(detailData).toLowerCase().replace(/ /g,'_'))} />
                    </ListItem>
                ))}
            </Box>
            {Object.keys(result).length > 6 &&
                <Button sx={{ mb: 3 }} onClick={() => setExpanded(!expanded)} variant="outlined">
                    {expanded ? `${t('show_less')} ${t(getTitle(data).toLowerCase().replace(/ /g,'_'))}` : `${t('show_all')} ${t(getTitle(data).toLowerCase().replace(/ /g,'_'))}`}
                </Button>}
        </>

    )
}

export default DetailFeaturesObject;